import React from 'react';
import { useState, useEffect } from 'react';
import {
  Flex,
  Form,
  Input,
  Tooltip,
  Box,
  Avatar,
  Button,
  Grid as FluentGrid,
  Dialog as FluentDialog,
  Text,
  TextArea,
    Loader,
    FormInput,
    FormDropdown
} from '@fluentui/react-northstar'
import {
  WindowMinimizeIcon,
  AddIcon,
  TrashCanIcon,
  EditIcon,
  SearchIcon,
  ClipboardCopiedToIcon
} from '@fluentui/react-icons-northstar'
import {
  Grid,
  GridColumn as Column,
  GridNoRecords,
} from "@progress/kendo-react-grid";
import { Dialog } from "@progress/kendo-react-dialogs";
import { orderBy } from "@progress/kendo-data-query";
import copy from "copy-to-clipboard";
import "../Dashboard/dashboard.scss";
import "./config.scss";
import ConfigurationApiService from '../../../services/ConfigurationApiService';
import AuthHelper from '../../../services/auth-helper';
import CenteredHeader from "../../Shared/Header/Header";
import WindowContext from "../../Shared/Context/Context";
import NativeDatePicker from "../../Shared/uicomponents/NativeDatePicker";
import TeamsDatePicker from "../../Shared/uicomponents/TeamsDatePicker";
import Impersonation from './Impersonation';
import { OPERATIONSTATUS, OperationStatusIndicator } from "../../Shared/uicomponents/OperationStatus/index";
import { Error403 } from "../../Shared/Error/403";
import { Error500 } from "../../Shared/Error/500";

const CreateNewToken = (props) => {

  const [loading, setLoading] = useState(false);
  const [tokenName, setTokenName] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const [userData, setUserData] = useState([]);
  const date = new Date();
  const startDate = new Date();
  date.setDate(date.getDate() + 30);
  startDate.setDate(startDate.getDate() + 1);
  const [expiryDate, setExpiryDate] = useState(date);
  const [calenderstartDate, setcalenderstartDate] = useState(startDate);
  const [isDemo, setIsDemo] = useState(false);
  const [isDemoMode, setIsDemoMode] = useState(global.localStorage.getItem("demoGlobal"));

  useEffect(() => {
    if (isDemoMode === "true") {
      setIsDemo(true);
    } else {
      setIsDemo(false);
    }
  }, []);


  console.log("expiryDate : " + expiryDate);
  useEffect(() => {
    loadUsersData();

  }, []);

  const loadUsersData = () => {
    setLoading(true);
    AuthHelper.getAccessToken(function (token) {
        ConfigurationApiService.GetUsers(token).then((response) => {
         if (response && response.status == 200) {
          var tempUsers = [];
          response.data.forEach((item) => {
            var data = {
              header: item.userName,
              image: { as: Avatar, image: item.userImage, name: item.userName },
              content: item.jobTitle,
              id: item.userGUID,
              key: item.userGUID,
              userEmail: item.email,
            };
            if (props.isLoggedInUserAdmin || (item.administrativeAccess !== 1 && item.administrativeAccessInherited !== 1)) {
              tempUsers.push(data);
            }
          });
          setUserData(tempUsers);
        }
        setLoading(false);
      });
    });
  }

  const handleTokenNameValue = (event) => {
    if (event.target.value != " ")
      setTokenName(event.target.value)
  }

  const handleKeyDown = (e) => {
    if (e.keyCode === 32) {
      e.stopPropagation();
    }
  }

  const handleUserSelection = {
    onAdd: (item) => { setSelectedUser(item); }
  };

  const handleUserSearchChange = (e) => {
    if (e.keyCode !== 13) {
      setSelectedUser(null);
    }
  }

  const submitForm = () => {
    if (selectedUser === null || tokenName.trim() === "" || expiryDate === "") {
      return;
    }
    setLoading(true);
    var tokenData = {
      tokenName: tokenName.trim(),
      userEmail: selectedUser.userEmail,
      userGUID: selectedUser.id,
      expiryDate: expiryDate
    }
    AuthHelper.getAccessToken(function (token) {
        ConfigurationApiService.CreateNewToken(tokenData, token).then((response) => {
         props.handleCreatedToken(response.status);
        
        setLoading(false);
      });
    });
  }

  return (
    <WindowContext.Consumer>
      {(context) =>
        loading ? <Loader /> :
          <Box>
            <Form className="" onSubmit={submitForm} styles={{ marginTop: "20px", fontSize: '0.9rem' }}>
              <FluentGrid className="whRow" columns={2} styles={{ width: '100%', alignItems: 'center', gridColumnGap: '8px', gridTemplateColumns: '100px calc(100% - 110px)', marginBottom: '15px !important' }}>
                <Text content="Token Name : " />
                <FormInput
                  style={{ width: "100%" }}
                  value={tokenName}
                  onChange={handleTokenNameValue}
                  onKeyDown={handleKeyDown}
                  fluid={true}
                  required
                />
              </FluentGrid>
              <FluentGrid className="whRow" columns={2} styles={{ width: '100%', alignItems: 'center', gridColumnGap: '8px', gridTemplateColumns: '100px calc(100% - 110px)', marginBottom: '15px !important' }}>
                <Text content="Select User : " />
                <FormDropdown
                  style={{ width: "100%" }}
                  search
                  items={userData}
                  placeholder="Select User"
                  getA11ySelectionMessage={handleUserSelection}
                  onKeyUp={handleUserSearchChange}
                  value={selectedUser}
                  itemToValue={(obj) => { return obj.key; }}
                  fluid={true}
                  required
                />
              </FluentGrid>
              <FluentGrid className="whRow" columns={2} styles={{ width: '100%', alignItems: 'center', gridColumnGap: '8px', gridTemplateColumns: '100px calc(100% - 110px)', marginBottom: '30px !important' }}>
                <Text content="Expiry Date : " />
                {context.teams.hostClientType.web ||
                  context.teams.hostClientType.desktop ? (
                  <TeamsDatePicker
                    value={expiryDate}
                    minDate={calenderstartDate}
                    onChange={(value) => setExpiryDate(value)}

                  />
                ) : (
                  <NativeDatePicker
                    id="expiry-date"
                    value={expiryDate}
                    minDate={calenderstartDate}
                    onChange={(value) => setExpiryDate(value)}
                  />
                )}
              </FluentGrid>
              <Flex space="between" styles={{ paddingTop: "50px" }}>
                <Box />
                <Flex gap="gap.small" className='align-items-center'>
                  {isDemo && <div className="col text-right px-0 ">Exploring app with sample data.</div>}
                  <Button
                    disabled={selectedUser === null || tokenName.trim() === "" || isDemo === true}
                    content="Save"
                    type="submit"
                    primary
                  />
                </Flex>
              </Flex>
            </Form>
          </Box>
      }
    </WindowContext.Consumer>
  );
}

const CellWithToken = (props) => {
  const tokenDetails = props.dataItem;
  const [iconColor, setIconColor] = useState("");

  const [isDemo, setIsDemo] = useState(false);
  const [isDemoMode, setIsDemoMode] = useState(global.localStorage.getItem("demoGlobal"));

  useEffect(() => {
    if (isDemoMode === "true") {
      setIsDemo(true);
    } else {
      setIsDemo(false);
    }
  }, []);

  const handleCopyToken = () => {
    if (!isDemo) {
      copy(tokenDetails.token);
      setIconColor("green");
      setTimeout(
        () => setIconColor(""),
        2000
      );
    }

  }

  return (
    <td>
      <Flex space="between">
        <Text style={{ color: iconColor }} content={tokenDetails.token} truncated />
        <Flex gap="gap.small">
          <Tooltip
            trigger={
              <ClipboardCopiedToIcon id={`${tokenDetails.token.slice(-5) + "_copy"}`} style={{ cursor: "pointer", color: iconColor }} onClick={handleCopyToken} disabled={isDemo} />
            }
            align="center"
            position="below"
            content={"Copy token"}
          />
          {!isDemo ? <FluentDialog
            cancelButton="Cancel"
            confirmButton="Delete"
            onConfirm={() => props.handleDeleteConfirm(tokenDetails)}
            closeOnOutsideClick={false}
            defaultOpen={false}
            styles={{ maxWidth: "500px" }}
            trigger={<TrashCanIcon id={`${tokenDetails.token.slice(-5) + "_delete"}`} style={{ cursor: "pointer" }} />}
            content={"Are you sure you want to delete this token?"}
          /> : <TrashCanIcon id={`${tokenDetails.token.slice(-5) + "_delete"}`} style={{ cursor: "pointer" }} disabled />}
        </Flex>
      </Flex>
    </td>
  );
};


const DetailComponent = (props) => {
  const token = props.dataItem;
  return (
    <section style={{ paddingRight: "32px" }}>
      <table>
        <tr>
          <td>User:</td>
          <td className="text-right">
            <Text content={token.userName} />
          </td>
        </tr>
        <tr>
          <td>Token:</td>
          <td className="text-right">
            <Text content={token.token} />
          </td>
        </tr>
      </table>
    </section>
  );
};


const APITokenConfig = (props) => {
  const [loading, setLoading] = useState(false);
  const [tokens, setTokens] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const [open, setOpen] = useState(false);
  const [sort, setSort] = useState([]);

    useEffect(() => {
        props.viewStatus.setPageStatus(null);
        props.viewStatus.InformChildPageStatus(null);
    loadTokensData();
  }, []);


  const expandChange = (event) => {
    let newData = tokens.map((item) => {
      if (item.tokenName === event.dataItem.tokenName) {
        item.expanded = !event.dataItem.expanded;
      }
      return item;
    });
    setTokens(newData);
  };

  const loadTokensData = () => {
    setTokens(null);
    setLoading(true);
    AuthHelper.getAccessToken(function (token) {
        ConfigurationApiService.GetTokens(token).then((response) => {
          
          if (response && response.status == 200) {
            setTokens(response.data);
          } else {
              setTokens([]);
          }

          props.viewStatus.setPageStatus(response.status);
        setLoading(false);
      });
    });
  }

  const handleCreatedToken = (status) => {
      if (status == 200) {
          loadTokensData();
          setOpen(false);
          props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Success);
      }
      else if (status == 401)
          props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Unauthorized);
      else
          props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
  }

  const handleDeleteConfirm = (tokenDetail) => {
    var tempToken = [...tokens];
    setTokens(null);
    setLoading(true);
    AuthHelper.getAccessToken(function (token) {
      ConfigurationApiService.DeleteToken(tokenDetail.tokenId, token).then((response) => {
        if (response && response.status == 200) {
          var indexId = tempToken.findIndex(
            (x) => x.tokenId === tokenDetail.tokenId
          );
            tempToken.splice(indexId, 1);
            props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Success);

          }
        else if (response && response.status == 401) {

            props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Unauthorized);

        }
        else {
            props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
        }
        setTokens(tempToken);
        setLoading(false);
      });
    });
  }

  const CustomCellWithToken = (props) => {
    return (
      <CellWithToken
        {...props}
        handleDeleteConfirm={handleDeleteConfirm}
      />
    );
  };

  return (
    <WindowContext.Consumer>
      {
              (context) => (loading ? <Loader label="loading..." /> :
                  props.viewStatus.pageStatus == 401 ? <Error403 /> :
                      props.viewStatus.pageStatus == 500 ? <Error500 /> :
          <div>
            <Flex className='mx-2 mb-2 mt-0' gap='gap.large' fill>
                <h5 className='m-0'>API Token</h5>
            </Flex>
            <Flex gap="gap.small" padding="padding.medium" fill>
              <Input
                  fluid
                  icon={<SearchIcon />}
                  clearable
                  value={searchInput}
                  placeholder="Search..."
                  onChange={(e, { name, value }) => {
                    setSearchInput(value);
                  }}
              />
              <Button
                  onClick={() => setOpen(true)}
                  icon={<AddIcon />}
                  content="Create New"
                  primary
                  styles={{ minWidth: 'auto' }}
              />
            </Flex>
            <Flex gap="gap.small" padding="padding.medium" column fill hAlign="end">
              <Flex.Item>
                <Grid
                  style={{ maxHeight: "calc(100vh - 115px)" }}
                  detail={context.mediaCategory.sm ? DetailComponent : null}
                  expandField="expanded"
                  onExpandChange={expandChange}
                  data={
                    tokens != null
                      ? orderBy(tokens.filter((obj) =>
                        obj.tokenName.toLowerCase().includes(searchInput.toLowerCase())
                      ), sort)
                      : null
                  }
                  sortable={true}
                  sort={sort}
                  onSortChange={(e) => {
                    setSort(e.sort);
                  }}
                >
                  <GridNoRecords>
                    {tokens != null ? "No records available" : <Loader />}
                  </GridNoRecords>
                  <Column
                    width={context.mediaCategory.sm ? "calc(100% - 170px)" : "calc(100% - 570px)"}
                    field="tokenName"
                    title="Token Name"
                  />
                  {!context.mediaCategory.sm && (
                    <Column
                      width="150px"
                      title="User"
                      field="userName"
                    />
                  )}
                  <Column
                    width={"150px"}
                    field="expiryDate"
                    title="Expiry Date"
                  />
                  {!context.mediaCategory.sm && (
                    <Column
                      width="250px"
                      title="Token"
                      field="token"
                      cell={CustomCellWithToken}
                      headerCell={CenteredHeader}
                    />
                  )}
                </Grid>
              </Flex.Item>
            </Flex>
            {open && (
              <Dialog
                style={{ overflow: "scroll" }}
                width={context.mediaCategory.sm ? null : 400}
                height={context.mediaCategory.sm ? null : 350}
                title={"Create New Token"}
                onClose={() => setOpen(false)}
              >
                <CreateNewToken
                  handleCreatedToken={handleCreatedToken}
                  isLoggedInUserAdmin={props.isLoggedInUserAdmin}
                  isLoggedInUserTeamOwner={props.isLoggedInUserAdmin}
                />
              </Dialog>
            )}
          </div>
        )
      }
    </WindowContext.Consumer>
  );
}

export default APITokenConfig;