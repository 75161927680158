import axios, { AxiosInstance } from 'axios';
import AuthHelper from './auth-helper';
import { AuthenticationResult } from '@azure/msal-browser';
import * as constants from '../constants';

class TrackUserActivityApiService {

    static AuthenticatedApi = axios.create({
        baseURL: constants.Api.baseUri,
        // will inject auth header on-demand later as needed.
        headers: { 
            'Content-Type': 'application/json',
        }
    });

    // static AddUserLoginActivity(loginActivityData) {
    //     return AuthHelper.GetToken()
    //         .then((response) => {
    
    //             return this.AuthenticatedApi.post('/TrackUserActivity/InsertUserLoginTracker/', loginActivityData,
    //             {
    //                 headers: {
    //                     Authorization: 'Bearer ' + response.accessToken
    //                 }
    //             })
    //             .then((response) => {
    //                 console.log('UserLoginActivityTracker added successfully.');
    //                 return "Success";
    //             })
    //             .catch((error) => {
    
    //                 console.log('TrackUserActivityApiService.AddUserLoginActivity() - An error has occurred calling the web api: ' + error);
    //                 return null;
    //             });
    //         })
    //         .catch((error) => {
    
    //             console.log('TrackUserActivityApiService.AddUserLoginActivity() - An error has occurred while retreiving the token: ' + error);
    //             return null;
    //         });
    // }
}

export default TrackUserActivityApiService;