import React, { useEffect, useState } from "react";
import CircleChart from "../../Shared/uicomponents/circleChart";
import "./ComparisonView.scss";
import WindowContext from "../../Shared/Context/Context";

const ComparisonView = (props) => {
  const [data, setData] = useState(props.data);
  if (data.sentiment1 === null)
    data.sentiment1 = "Neutral";
  if (data.sentiment2 === null)
    data.sentiment2 = "Neutral";
  if (data.teamSummary1.latestActivityTime === null)
    data.teamSummary1.latestActivityTime = "Unavailable";
  if (data.teamSummary2.latestActivityTime === null)
    data.teamSummary2.latestActivityTime = "Unavailable";
  if (data.timeInMeeting1 === null)
    data.timeInMeeting1 = "00:00:00"
  if (data.timeInMeeting2 === null)
    data.timeInMeeting2 = "00:00:00"

  return (
      <WindowContext.Consumer>{
      (context) => (
        <div className="comparisonView">
          <div className="compareGraph">
            {!context.mediaCategory.sm &&
              <div className="compareTrk compareTm d-flex justify-content-center align-items-center w-tc-45">
                <div className="topUsrArea">
                  <div className="topUsrAreaPic">
                    <div className="topUsrAreaPic-i">
                      <div className="contact-list-icon">
                        <div className="contact-list-icon-txt text-primary">{data.initials1}</div>
                      </div>
                    </div>
                  </div>
                  <div className="topUsrAreaDtlLbl">
                    <div className="topUsrAreaDtl-t text-secondary">
                      <span>{data.displayName1}</span>
                    </div>
                    <div className="topUsrAreaDtlLblRow">
                      <div className="topUsrAreaDtlLblRow-h">Membership</div>
                      <div className="topUsrAreaDtlLblRow-val">
                        {data.teamSummary1.totalUsers}
                      </div>
                    </div>
                    <div className="topUsrAreaDtlLblRow">
                      <div className="topUsrAreaDtlLblRow-h">Active members(owners)</div>
                      <div className="topUsrAreaDtlLblRow-val">
                        {data.teamSummary1.activeOwnersMembers}
                      </div>
                    </div>
                    <div className="topUsrAreaDtlLblRow">
                      <div className="topUsrAreaDtlLblRow-h">Inactive members(owners)</div>
                      <div className="topUsrAreaDtlLblRow-val">
                        {data.teamSummary1.inactiveOwnersMembers}
                      </div>
                    </div>
                    {/* <div className="topUsrAreaDtlLblRow">
                    <div className="topUsrAreaDtlLblRow-h">Owners</div>
                    <div className="topUsrAreaDtlLblRow-val">
                      {data.teamSummary1.owners}
                    </div>
                  </div> */}
                  </div>
                  <div className="topUsrAreaDate">
                    {/* <div className="topUsrAreaDateTrk">
                            <div className="topUsrAreaDateTrk-p">Created</div>
                            <div className="topUsrAreaDateTrk-val">24/05/2020</div>
                        </div> */}
                    <div className="topUsrAreaDateTrk text-center">
                      <div className="topUsrAreaDateTrk-p">Last Activity</div>
                      <div className="topUsrAreaDateTrk-val">
                        {data.teamSummary1.latestActivityTime}
                      </div>
                    </div>
                  </div>
                </div>
              </div>}
            <div className="compareTrk compareData">
              <div className="compareDataInr">
                {context.mediaCategory.sm &&
                  <>
                    <div className="shadow compareDataInrRow">
                      <div className="compareDataInrRow-val">
                        {data.displayName1}
                      </div>
                      <div className="compareDataInrRow-p" >&#129308; Team &#129307;</div>
                      <div className="compareDataInrRow-val">
                        {data.displayName2}
                      </div>
                    </div>
                    <div className="compareDataInrRow">
                      <div className="compareDataInrRow-val-cstm">
                        {data.teamSummary1.totalUsers}
                      </div>
                      <div className={context.mediaCategory.sm ? "text-cst-sec compareDataInrRow-p fs_09em" : "text-cst-sec compareDataInrRow-p"} >Membership</div>
                      <div className="compareDataInrRow-val-cstm">
                        {data.teamSummary2.totalUsers}
                      </div>
                    </div>
                    <div className="compareDataInrRow">
                      <div className="compareDataInrRow-val-cstm">
                        {data.teamSummary1.activeOwnersMembers}
                      </div>
                      <div className={context.mediaCategory.sm ? "text-cst-sec compareDataInrRow-p fs_09em" : "text-cst-sec compareDataInrRow-p"} >Active members(owners)</div>
                      <div className="compareDataInrRow-val-cstm">
                        {data.teamSummary2.activeOwnersMembers}
                      </div>
                    </div>
                    <div className="compareDataInrRow">
                      <div className="compareDataInrRow-val-cstm">
                        {data.teamSummary1.inactiveOwnersMembers}
                      </div>
                      <div className={context.mediaCategory.sm ? "text-cst-sec compareDataInrRow-p fs_09em" : "text-cst-sec compareDataInrRow-p"} >Inactive members(owners)</div>
                      <div className="compareDataInrRow-val-cstm">
                        {data.teamSummary2.inactiveOwnersMembers}
                      </div>
                    </div>
                    <div className="compareDataInrRow mb-2">
                      <div className="compareDataInrRow-val-cstm">
                        {data.teamSummary1.latestActivityTime}
                      </div>
                      <div className={context.mediaCategory.sm ? "text-cst-sec compareDataInrRow-p fs_09em" : "text-cst-sec compareDataInrRow-p"} >Last Activity</div>
                      <div className="compareDataInrRow-val-cstm">
                        {data.teamSummary2.latestActivityTime}
                      </div>
                    </div>
                  </>}
                {/* <div className="compareDataInrRow tmRowTitle">
                  <div className="compareDataInrRow-val"></div>
                   <div className={context.mediaCategory.sm ? "text-cst-sec compareDataInrRow-p fs_09em" : "text-cst-sec compareDataInrRow-p"} >Team Stats</div> 
                  <div className="compareDataInrRow-val"></div>
                </div> */}
                <div className={context.mediaCategory.sm ? "compareDataInrRow border-top" : "compareDataInrRow"}>
                  <div className="compareDataInrRow-val">
                    {data.countMeeting1}
                  </div>
                  <div className={context.mediaCategory.sm ? "text-cst-sec compareDataInrRow-p fs_09em" : "text-cst-sec compareDataInrRow-p"} >Team meetings</div>
                  <div className="compareDataInrRow-val">
                    {data.countMeeting2}
                  </div>
                </div>
                <div className="compareDataInrRow">
                  <div className="compareDataInrRow-val">{data.timeInMeeting1}</div>
                  <div className={context.mediaCategory.sm ? "text-cst-sec compareDataInrRow-p fs_09em" : "text-cst-sec compareDataInrRow-p"} >Time in meetings</div>
                  <div className="compareDataInrRow-val">{data.timeInMeeting2}</div>
                </div>
                <div className="compareDataInrRow">
                  <div className="compareDataInrRow-val">
                    {data.recurrence1}
                  </div>
                  <div className={context.mediaCategory.sm ? "text-cst-sec compareDataInrRow-p fs_09em" : "text-cst-sec compareDataInrRow-p"} >Recurring(%)</div>
                  <div className="compareDataInrRow-val">
                    {data.recurrence2}
                  </div>
                </div>
                <div className="compareDataInrRow">
                  <div className="compareDataInrRow-val">
                    {data.external1}
                  </div>
                  <div className={context.mediaCategory.sm ? "text-cst-sec compareDataInrRow-p fs_09em" : "text-cst-sec compareDataInrRow-p"} >
                    External Meetings(%)
                  </div>
                  <div className="compareDataInrRow-val">
                    {data.external2}
                  </div>
                </div>

                <div className="compareDataInrRow">
                  <div className="compareDataInrRow-val">{data.sent1}</div>
                  <div className={context.mediaCategory.sm ? "text-cst-sec compareDataInrRow-p fs_09em" : "text-cst-sec compareDataInrRow-p"} >Posts(External)</div>
                  <div className="compareDataInrRow-val">{data.sent2}</div>
                </div>
                <div className="compareDataInrRow">
                  <div className="compareDataInrRow-val">{data.replied1}</div>
                  <div className={context.mediaCategory.sm ? "text-cst-sec compareDataInrRow-p fs_09em" : "text-cst-sec compareDataInrRow-p"} >Replies(External)</div>
                  <div className="compareDataInrRow-val">{data.replied2}</div>
                </div>
                <div className="compareDataInrRow">
                  <div className="compareDataInrRow-val">
                    {data.mentionedCount1}
                  </div>
                  <div className={context.mediaCategory.sm ? "text-cst-sec compareDataInrRow-p fs_09em" : "text-cst-sec compareDataInrRow-p"} >
                    Mentions(External)
                  </div>
                  <div className="compareDataInrRow-val">
                    {data.mentionedCount2}
                  </div>
                </div>
                <div className="compareDataInrRow">
                  <div className="compareDataInrRow-val">{data.reactionCount1}</div>
                  <div className={context.mediaCategory.sm ? "text-cst-sec compareDataInrRow-p fs_09em" : "text-cst-sec compareDataInrRow-p"} >Reactions(External)</div>
                  <div className="compareDataInrRow-val">{data.reactionCount2}</div>
                </div>
                <div className="compareDataInrRow">
                  <div className="compareDataInrRow-val">
                    <div className="progress" style={{ height: ".5rem", width: "90%" }}>
                      <div className="progress-bar bg-success" style={{ width: `${(data.goodSentiment1 * 100) / (data.goodSentiment1 + data.neutralSentiment1 + data.poorSentiment1)}%` }} title={`good : ${data.goodSentiment1}`}>
                      </div>
                      <div className="progress-bar bg-warning border-left" style={{ width: `${(data.neutralSentiment1 * 100) / (data.goodSentiment1 + data.neutralSentiment1 + data.poorSentiment1)}%` }} title={`Average : ${data.neutralSentiment1}`}>
                      </div>
                      <div className="progress-bar bg-danger border-left" style={{ width: `${(data.poorSentiment1 * 100) / (data.goodSentiment1 + data.neutralSentiment1 + data.poorSentiment1)}%` }} title={`Poor : ${data.poorSentiment1}`}>
                      </div>
                    </div>
                  </div>

                  <div className={context.mediaCategory.sm ? "text-cst-sec compareDataInrRow-p fs_09em" : "text-cst-sec compareDataInrRow-p"} >
                    Sentiment
                  </div>

                  <div className="compareDataInrRow-val">
                    <div className="progress" style={{ height: ".5rem", width: "90%" }}>
                      <div className="progress-bar bg-success" style={{ width: `${(data.goodSentiment2 * 100) / (data.goodSentiment2 + data.neutralSentiment2 + data.poorSentiment2)}%` }} title={`good : ${data.goodSentiment2}`}>
                      </div>
                      <div className="progress-bar bg-warning border-left" style={{ width: `${(data.neutralSentiment2 * 100) / (data.goodSentiment2 + data.neutralSentiment2 + data.poorSentiment2)}%` }} title={`Average : ${data.neutralSentiment2}`}>
                      </div>
                      <div className="progress-bar bg-danger border-left" style={{ width: `${(data.poorSentiment2 * 100) / (data.goodSentiment2 + data.neutralSentiment2 + data.poorSentiment2)}%` }} title={`Poor : ${data.poorSentiment2}`}>
                      </div>
                    </div>
                  </div>
                </div>

                {/*<div className="compareDataInrRow">*/}
                {/*  <div className="compareDataInrRow-val">*/}
                {/*    {data.outOfHours1}*/}
                {/*  </div>*/}
                {/*  <div className={context.mediaCategory.sm ? "text-cst-sec compareDataInrRow-p fs_09em" : "text-cst-sec compareDataInrRow-p"} >*/}
                {/*    % OOH*/}
                {/*  </div>*/}
                {/*  <div className="compareDataInrRow-val">*/}
                {/*    {data.outOfHours2}*/}
                {/*  </div>*/}
                {/*</div>*/}

              </div>
            </div>
            {!context.mediaCategory.sm &&
              <div className="compareTrk compareTm d-flex justify-content-center align-items-center w-tc-45" id="chooseTeamData-right-btn">
                <div className="topUsrArea">
                  <div className="topUsrAreaPic">
                    <div className="topUsrAreaPic-i">
                      <div className="contact-list-icon">
                        <div className="contact-list-icon-txt text-primary">{data.initials2}</div>
                      </div>
                    </div>
                  </div>
                  <div className="topUsrAreaDtlLbl">
                    <div className="topUsrAreaDtl-t text-secondary">
                      <span>{data.displayName2}</span>
                    </div>
                    <div className="topUsrAreaDtlLblRow">
                      <div className="topUsrAreaDtlLblRow-h">Membership</div>
                      <div className="topUsrAreaDtlLblRow-val">
                        {data.teamSummary2.totalUsers}
                      </div>
                    </div>
                    <div className="topUsrAreaDtlLblRow">
                      <div className="topUsrAreaDtlLblRow-h">Active members(owners)</div>
                      <div className="topUsrAreaDtlLblRow-val">
                        {data.teamSummary2.activeOwnersMembers}
                      </div>
                    </div>
                    <div className="topUsrAreaDtlLblRow">
                      <div className="topUsrAreaDtlLblRow-h">Inactive members(owners)</div>
                      <div className="topUsrAreaDtlLblRow-val">
                        {data.teamSummary2.inactiveOwnersMembers}
                      </div>
                    </div>
                    {/* <div className="topUsrAreaDtlLblRow">
                    <div className="topUsrAreaDtlLblRow-h">Owners</div>
                    <div className="topUsrAreaDtlLblRow-val">
                      {data.teamSummary2.owners}
                    </div>
                  </div> */}
                  </div>
                  <div className="topUsrAreaDate">
                    {/* <div className="topUsrAreaDateTrk">
                            <div className="topUsrAreaDateTrk-p">Created</div>
                            <div className="topUsrAreaDateTrk-val">24/05/2020</div>
                        </div> */}
                    <div className="topUsrAreaDateTrk text-center">
                      <div className="topUsrAreaDateTrk-p">Last Activity</div>
                      <div className="topUsrAreaDateTrk-val">
                        {data.teamSummary2.latestActivityTime}
                      </div>
                    </div>
                  </div>
                </div>
              </div>}
          </div>
        </div>
      )}</WindowContext.Consumer>
  );
};

export default ComparisonView;
