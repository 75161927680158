import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { Avatar, Tooltip, Popup, Box, Flex, Text, Loader, Button } from "@fluentui/react-northstar";
import "./GridFilteredByInsight.scss";
import { CircleIcon, CloseIcon } from "@fluentui/react-icons-northstar";
import * as React from "react";
import { orderBy } from "@progress/kendo-data-query";
import { ExcelExport } from '@progress/kendo-react-excel-export';
import WindowContext from "../../Shared/Context/Context";
import FiltersApiService from "../../../services/FiltersApiService";
import AuthHelper from "../../../services/auth-helper";

const CellWithUsers = (props) => {
  const data = props.data;
  const [show, setShow] = React.useState(false);
  var isFullScreen = false;
  if (( document.fullscreenElement !== undefined &&  document.fullscreenElement !== null)|| ( document.mozFullScreenElement !== undefined &&  document.mozFullScreenElement !== null)
  ||( document.msFullscreenElement !== undefined &&  document.msFullscreenElement !== null) ||( document.webkitFullscreenElement !== undefined &&  document.webkitFullscreenElement !== null)) 
 {
  isFullScreen = true
 } 
  return (
    <td>
      {data.length > 2 ?
        <>
          {!show ? <Tooltip
            content={data[0].name}
            trigger={<Avatar name={data[0].name} title={data[0].name} image={data[0].image} />}
          />: <></>} 
          {!show? <Tooltip
            content={data[1].name}
            trigger={<Avatar name={data[1].name} title={data[1].name} image={data[1].image} />}
          /> : <></>}

          {!isFullScreen && <Popup
            trigger={<Avatar label={"+" + (data.length - 2)} />}
            on="hover"
            pointing
            content={
              <div style={{ maxWidth: "400px" }}>
                {
                  data.slice(2).map(user => {
                    return <Tooltip
                      content={user.name}
                      trigger={<Avatar name={user.name} image={user.image} />}
                    />
                  })
                }
              </div>
            }
          /> }
          {isFullScreen && <Avatar className="cursor-pointer" label={show ? <CloseIcon style={{color : "red", textShadow: "2px 2px #cccccc"}} /> : "+" + (data.length - 2)} onClick={() => show? setShow(false) : setShow(true)}/>}
          {show && isFullScreen ? <div className="rounded p-1 bg-white" style={{ maxWidth: "400px", maxHeight:"100px", overflow: "auto" }}>
                {
                  data.map(user => {
                    return <Tooltip
                      content={user.name}
                      trigger={<Avatar name={user.name} title={user.name} image={user.image} />}
                    />
                  })
                }
              </div> : <></>}
        </>
        :
        data.map(user => {
          return <Tooltip
            content={user.name}
            trigger={<Avatar name={user.name} title={user.name} image={user.image} />}
          />
        })
      }
    </td>
  )
}


const TeamChannelDetails = (props) => {
  const [data, setData] = React.useState();
  const [isLoaded, setIsLoaded] = React.useState(false);
  
React.useEffect(()=> {
  if(props.filters === undefined)
  {
     setData(props.dataItem?.details);
     setIsLoaded(true);

  }
  else
  {
  
  AuthHelper.getAccessToken(function (token) {   
    FiltersApiService.CardAPICallForChannel(
      props.filters,props.dataItem.teamName,
      token
  ).then((response) => {
      if (response != null) {
          setData(response);
          setIsLoaded(true);
      } else {
          setData(response);
          setIsLoaded(true);
      }
    })
  })
    
}
},[props?.dataItem?.teamName]);
  return isLoaded?(
    <Grid data={data}>
      <Column field="ChannelName" title="Channel Name" locked width={120} />
      <Column field="LastActivity" title="Last Activity" width={72} />
      {data[0].ActiveUsers !== 0 ? <Column field="ActiveUsers"  title="Active Users" width={90} cell={(props) => (<CellWithUsers data = {data[props.dataIndex].ActiveUsersList}/>)}/> 
                                                    : <Column field="ActiveUsers" title="Active Users" width={90} />}
    </Grid>
  ):<Loader/>;

};

export default function GridFilteredByInsight({ data, widgetId, downloadCard, valueAfterCardDownload,filters }) {
  const [sort, setSort] = React.useState([]);
  const [gridData, setGridData] = React.useState(data.gridData);
  const [activeInsight, setActiveInsight] = React.useState(1);
  const [cardDownload, setCardDownload] = React.useState(false);
  const _export = React.useRef(null);
  const teamGovernanceInsights = [
    { id: 1, key: 1, name: "", header: "Total Teams", value: data.gridData.length },
    //{ id: 2, key: 2, name: "", header: "Teams with more than 20 owners", value: data.gridData.filter(e => e.teamOwners.length > 20).length },
    { id: 3, key: 3, name: "", header: "Teams with External members", value: data.gridData.filter(e => e.members.filter(m => m.isExternal).length > 0).length },
    { id: 4, key: 4, name: "", header: "Teams with duplicate membership", value: data.gridData.filter(e => e.isDuplicate).length },
    //{ id: 5, key: 5, name: "", header: "Teams with less than 2 owners", value: data.gridData.filter(e => e.teamOwners.length < 2).length },
    //{ id: 6, key: 6, name: "", header: "Teams with more than 100 members", value: data.gridData.filter(e => e.members.length > 100).length },
    { id: 7, key: 7, name: "", header: "Inactive Teams", value: data.gridData.filter(e => e.lastActivity === "").length },
    { id: 8, key: 8, name: "", header: "Teams with no owner", value: data.gridData.filter(e => e.teamOwners.length === 0).length },
    //{ id: 9, key: 9, name: "", header: "Teams with less than 5 members", value: data.gridData.filter(e => e.members.length < 5).length },
    // { id: 10, key: 10, name: "", header: "Teams with more than 25 channels", value: data.gridData.filter(e => e.channelCount > 25).length },
    { id: 11, key: 11, name: "", header: "Teams with Deactivated users", value: data.gridData.filter(e => (e.deactivatedUsers.length > 0)).length },
    // { id: 12, key: 12, name: "", header: "Inactive Teams", value: data.gridData.filter(e => e.lastActivity === "").length },
    //{ id: 13, key: 13, name: "", header: "Teams with less than 5 channels", value: data.gridData.filter(e => e.channelCount < 5).length },
    //{ id: 14, key: 14, name: "", header: "Teams with all disabled owners", value: data.gridData.filter(e => e.teamOwners.filter(m => m.isDisabled).length === e.teamOwners.length && e.teamOwners.length > 0).length },
    //{ id: 15, key: 15, name: "", header: "Active Teams", value: data.gridData.filter(e => e.lastActivity !== "").length }
  ];

  React.useEffect(() => {
    setCardDownload(downloadCard)
    if (cardDownload) {
      if (_export.current !== null) {
        _export.current.save();
      }
      setCardDownload(false);
      valueAfterCardDownload(false);
    }
  });

  const handleTeamGovernanceFilter = (insightId) => {
   setActiveInsight(insightId);
    if (insightId === 1) {
      setGridData(data.gridData);
    }
    else if (insightId === 2) {
      setGridData(data.gridData.filter(e => e.teamOwners.length > 20));
    }
    else if (insightId === 3) {
      setGridData(data.gridData.filter(e => e.members.filter(m => m.isExternal).length > 0));
    }
    else if (insightId === 4) {
      setGridData(data.gridData.filter(e => e.isDuplicate));
    }
    else if (insightId === 5) {
      setGridData(data.gridData.filter(e => e.teamOwners.length < 2));
    }
    else if (insightId === 6) {
      setGridData(data.gridData.filter(e => e.members.length > 100));
    }
    else if (insightId === 7) {
     setGridData(data.gridData.filter(e => e.lastActivity === ""));
    }
    else if (insightId === 8) {
      setGridData(data.gridData.filter(e => e.teamOwners.length === 0));
    }
    else if (insightId === 9) {
      setGridData(data.gridData.filter(e => e.members.length < 5));
    }
    else if (insightId === 10) {
      setGridData(data.gridData.filter(e => e.channelCount > 25));
    }
    else if (insightId === 11) {
      setGridData(data.gridData.filter(e => e.deactivatedUsers.length > 0));
    }
    // else if (insightId === 7) { e => e.teamType === "Private" 
    //   setGridData(data.gridData.filter(e => e.lastActivity === ""));
    // }
    else if (insightId === 13) {
      setGridData(data.gridData.filter(e => e.channelCount < 5));
    }
    else if (insightId === 14) {
      setGridData(data.gridData.filter(e => e.teamOwners.filter(m => m.isDisabled).length === e.teamOwners.length && e.teamOwners.length > 0));
    }
    else if (insightId === 15) {
      setGridData(data.gridData.filter(e => e.lastActivity !== ""));
    }
  }
  const expandChange = (event) => {
    let newData = gridData.map((item) => {
      if (item.teamName === event.dataItem.teamName) {
        item.expanded = !event.dataItem.expanded;
      }
      return item;
    });
    setGridData(newData);
  };
  return (
    <WindowContext.Consumer>
      {(context) => (
        <>
          {data.gridData && data.gridData.length > 0 && widgetId === 56 && (
            <div className="container-fluid h-100" style={{ overflowX: "hidden" }}>
              <div className="row py-2">
                {teamGovernanceInsights.map(insight => {
                  return <div className="col-md-4">
                    <div
                      className={activeInsight === insight.id ? "lbl-row-cst d-flex active" : "lbl-row-cst d-flex"}
                      onClick={(e) => handleTeamGovernanceFilter(insight.id)}
                    >
                      <div className="lbl-col-p-cst">{insight.header}</div>
                      <div className="lbl-col-h-cst ml-auto rounded-none">{insight.value}</div>
                    </div>
                  </div>
                })}
              </div>
              <div className="row"  >
                <ExcelExport data={gridData} ref={_export}>
                  <Grid
                    className="standardGrid"
                    style={{ maxHeight: "100%", width: "100%" }}
                    data={orderBy(gridData, sort)}
                    sortable={true}
                    sort={sort}
                    detail = {(props)=><TeamChannelDetails {...props} filters = {filters}/>}
                    onSortChange={(e) => {
                      setSort(e.sort);
                    }}
                    expandField="expanded"
                    onExpandChange={expandChange}
                  >
                    <Column field="teamName" title="Team Name" width={100} locked />
                    <Column field="teamType" title="Team Type" width={80} />
                    <Column field="memExternal" title="Membership (External)" width={80} locked />
                    <Column field="teamOwners" title="Owners" width={120} cell={(props) => (<CellWithUsers data = {props.dataItem.teamOwners}/>)} />
                    <Column field="members" title="Members" width={120} cell={(props) => (<CellWithUsers data = {props.dataItem.members}/>)} />
                    <Column field="activeUsers" title="Active Users" width={120} cell={(props) => (<CellWithUsers data = {props.dataItem.activeUsers}/>)}/>
                    <Column field="inactiveUsers" title="Inactive Users" width={120} cell={(props) => (<CellWithUsers data = {props.dataItem.inactiveUsers}/>)}/>
                    <Column field="deactivatedUsers" title="Deactivated Users" width={120} cell={(props) => (<CellWithUsers data = {props.dataItem.deactivatedUsers}/>)}/>
                    <Column field="lastActivity" title="Last Activity" width={90} />
                    <Column field="createdDate" title="Date Created" width={90} />
                    
                  </Grid>
                </ExcelExport>
              </div>
            </div>
          )}
        </>
      )
      }
    </WindowContext.Consumer >
  );
}


