function formatDateToISO(date, dateOnly) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const day = String(date.getDate()).padStart(2, '0');
    const hours = dateOnly ? '00' : String(date.getHours()).padStart(2, '0');
    const minutes = dateOnly ? '00' : String(date.getMinutes()).padStart(2, '0');
    const seconds = dateOnly ? '00' : String(date.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
}


export const generationRandomTimelineData = (startDate, endDate) => {
    function getRandomTime(existingTimes) {
        let time;
        do {
            time = Math.floor(Math.random() * (24 * 60)); // Random time within a day in minutes
        } while (existingTimes.some(t => Math.abs(t - time) < 30)); // Avoid too much overlap
        return time;
    }

    function getRandomDuration() {
        return Math.floor(Math.random() * (120 - 15 + 1)) + 15; // Between 15 and 120 minutes
    }

    function getRandomOverrun() {
        return Math.random() < 0.3 ? Math.floor(Math.random() * 30) : 0; // 30% chance of overrun
    }

    const meetingTitles = [
        "Project Sync", "Client Call", "Scrum Meeting", "Design Review", "Sales Call",
        "One-on-One", "Team Meeting", "Training Session", "Strategy Discussion", "Performance Review"
    ];

    let scheduleData = [];
    const currentDate = new Date(startDate);
    while (currentDate <= endDate) {
        let totalDuration = 0;
        const events = [];
        const existingTimes = [];
        const numEvents = Math.floor(Math.random() * (12 - 8 + 1)) + 8; // 8-12 events per day

        for (let j = 0; j < numEvents; j++) {
            if (totalDuration >= 12 * 60) break;

            const isMeeting = Math.random() < 0.6; // 60% chance of being a meeting
            const time = getRandomTime(existingTimes);
            const duration = getRandomDuration();
            const overrun = isMeeting ? getRandomOverrun() : 0;

            events.push({
                date: formatDateToISO(currentDate, true),
                time,
                duration,
                title: isMeeting ? meetingTitles[Math.floor(Math.random() * meetingTitles.length)] : "",
                type: isMeeting ? "meeting" : "call",
                overrunDuration: overrun
            });
            existingTimes.push(time);
            totalDuration += duration + overrun;
        }

        scheduleData.push(...events);
        currentDate.setDate(currentDate.getDate() + 1);
    }

    return scheduleData;
}

export const teamData = {
    teamCallsPercentage: 20,
    teamCallsInternalPercentage: "72",
    teamCallsExternalPercentage: "28",
    teamCallsInternal: "02:02:00",
    teamCallsExternal: "03:02:00",
    teamMsgPercentage: 24,
    teamMsgInternal: "1202(04:00:00)",
    teamMsgExternal: "700(01:00:00)",
    teamMsgInternalPercentage: "55",
    teamMsgExternalPercentage: "45",
    teamVibe: 42.567,
    vibeDiff: -12,
    teamSentimentVal: 2.5

}
export const manager = {
    userName: "Bob Fleming",
    userImage: null,
    userStatus: "Success",
    userGUID: "uuuuuuuu-uuuu-uuuu-uuuu-uuuuuuuuubob",
    userDesignation: "Engineering Manager",
    userEmail: "userbob.fleming@countrymatters.co.uk",
    meetingsPercentage: 20,
    meetingsZone: [18, 24, 36, 42],
    callsPercentage: 16,
    callsZone: [39, 52, 78, 91],
    vibe: "positive",
    topSentimentUsers: [],
    reporteeCount: 4,
    progressType:
        "productive-hours",
    positiveSentimentUsersList: [
        {
            key: 'uuuuuuuu-uuuu-uuuu-uuuu-uuuuuuuclive',
            value
                :
            {
                overallMessageCount: 2, msgCount: 2, negativeMsgCount: 1, sentiment: 0.6, photo: '', designation: "team lead", displayName: "Clive Tucker",

            }
        }],
    positiveSentimentCount: 1,
    neutralSentimentUsersList: [],
    neutralSentimentCount: 0,
    negativeSentimentUsersList: [
        {
            key: 'uuuuuuuu-uuuu-uuuu-uuuu-uuuuclarissa',
            value
                :
            {
                overallMessageCount: 2, msgCount: 2, negativeMsgCount: 1, sentiment: -0.06, photo: '', designation: "senior software developer", displayName: "Clarissa Wind",

            }
        },
        {
            key: 'uuuuuuuu-uuuu-uuuu-uuuu-uuuuumurtagh',
            value
                :
            {
                overallMessageCount: 4, msgCount: 3, negativeMsgCount: 0, sentiment: -0.2, photo: '', designation: "software developer", displayName: "Murtagh Blethyn",
            }
        }
    ],
    lvTypeFocused: "Positive",
    lvTypeMeeting:"Negative",
    lvTypeMessages: "Neutral",
    focusedZone: [4, 5, 7, 8],
    focusedPercentage: 64,
    expectedMeetingsPercentage: 24,
    expectedFocusedPercentage: 16,
    expectedCallsPercentage: 70,
    categoryTag: [
        { label:'Company and Vision', val: 2 },
        { label: 'Employee Satisfaction', val: 4 },
        { label: 'Team Dynamics', val: 3 },
        { label: 'Performance and Recognition', val: 1 },
    ],
    activities: [
        {
            date: formatDateToISO(new Date(new Date().setDate(1))),
            time: 600,
            duration: 90,
            title: "daily catchup",
            type: "meeting",
            overrunDuration: 20
        },
        {
            date: formatDateToISO(new Date()),
            time: 800,
            duration: 70,
            title: "Review Meeting",
            type: "meeting",
            overrunDuration: 10
        },
        {
            date: formatDateToISO(new Date(new Date().setDate(1))),
            time: 300,
            duration: 20,
            title: "",
            type: "call",
            overrunDuration: 0
        },
        {
            date: formatDateToISO(new Date()),
            time: 300,
            duration: 30,
            title: "",
            type: "call",
            overrunDuration: 0
        },
        {
            date: formatDateToISO(new Date()),
            time: 800,
            duration: 70,
            title: "Client Meeting",
            type: "meeting",
            overrunDuration: 20
        },
        {
            date: formatDateToISO(new Date(new Date().setDate(1))),
            time: 300,
            duration: 20,
            title: "",
            type: "call",
            overrunDuration: 0
        },
        {
            date: formatDateToISO(new Date()),
            time: 720,
            duration: 30,
            title: "",
            type: "call",
            overrunDuration: 0
        },
        {
            date: formatDateToISO(new Date()),
            time: 1300,
            duration: 30,
            title: "Team activity",
            type: "meeting",
            overrunDuration: 10
        },
        {
            date: formatDateToISO(new Date()),
            time: 780,
            duration: 20,
            title: "",
            type: "call",
            overrunDuration: 0
        },
        {
            date: formatDateToISO(new Date()),
            time: 1600,
            duration: 10,
            title: "Catchup",
            type: "meeting",
            overrunDuration: 0
        },
        {
            date: formatDateToISO(new Date(new Date().setDate(1))),
            time: 350,
            duration: 20,
            title: "",
            type: "call",
            overrunDuration: 0
        },
        {
            date: formatDateToISO(new Date()),
            time: 780,
            duration: 30,
            title: "",
            type: "call",
            overrunDuration: 0
        },
        {
            date: formatDateToISO(new Date(new Date().setDate(1))),
            time: 350,
            duration: 20,
            title: "",
            type: "call",
            overrunDuration: 0
        },
        {
            date: formatDateToISO(new Date()),
            time: 780,
            duration: 30,
            title: "",
            type: "call",
            overrunDuration: 0
        },
        {
            date: formatDateToISO(new Date(new Date().setDate(1))),
            time: 350,
            duration: 20,
            title: "",
            type: "call",
            overrunDuration: 0
        },
        {
            date: formatDateToISO(new Date()),
            time: 780,
            duration: 30,
            title: "",
            type: "call",
            overrunDuration: 0
        },
    ]
       
}
export const userData = [    
    {
        userName: "Jed Thomas",
        userImage: null,
        userStatus: "Success",
        userDesignation: "software developer",
        userEmail: "userjed.thomas@countrymatters.co.uk",
        userGUID: "uuuuuuuu-uuuu-uuuu-uuuu-uuuuuuuuujed",
        meetingsPercentage: 30,
        meetingsZone: [18, 24, 36, 42],
        callsPercentage: 26,
        callsZone: [39, 52, 78, 91],
        vibe: "positive",
        topSentimentUsers: [],
        reporteeCount: 4,
        progressType:
            "productive-hours",
        positiveSentimentUsersList: [
            {
                key: 'uuuuuuuu-uuuu-uuuu-uuuu-uuuuuuuclive',
                value
                    :
                {
                    overallMessageCount: 2, msgCount: 2, negativeMsgCount: 1, sentiment: 0.6, photo: '', designation: "team lead", displayName: "Clive Tucker",

                }
            }
        ],
        positiveSentimentCount: 1,
        neutralSentimentUsersList: [],
        neutralSentimentCount: 0,
        negativeSentimentUsersList: [
            {
                key: 'uuuuuuuu-uuuu-uuuu-uuuu-uuuuclarissa',
                value
                    :
                {
                    overallMessageCount: 2, msgCount: 2, negativeMsgCount: 1, sentiment: -0.06, photo: '', designation: "senior software developer", displayName:"Clarissa Wind",
                       
                }
            },
            {
                key: 'uuuuuuuu-uuuu-uuuu-uuuu-uuuuumurtagh',
                value
                    :
                {
                    overallMessageCount: 4, msgCount: 3, negativeMsgCount: 0, sentiment: -0.2, photo: '', designation: "software developer", displayName: "Murtagh Blethyn",
                }
            }
        ],
        lvTypeFocused: "Positive",
        lvTypeMeeting: "Negative",
        lvTypeMessages: "Neutral",
        focusedZone: [4, 5, 7, 8],
        focusedPercentage: 44,
        expectedMeetingsPercentage: 34,
        expectedFocusedPercentage: 26,
        expectedCallsPercentage: 80,
        categoryTag: [
            { label: 'Company and Vision', val: 1 },
            { label: 'Employee Satisfaction', val: 2 },
            { label: 'Team Dynamics', val: 4 },
            { label: 'Performance and Recognition', val: 2 },
        ],
        activities: []
    },
    {
        userName: "Clarissa Wind",
        userImage: null,
        userStatus: "Success",
        userDesignation: "senior software developer",
        userGUID: "uuuuuuuu-uuuu-uuuu-uuuu-uuuuclarissa",
        userEmail: "userclarissa.wind@countrymatters.co.uk",
        meetingsPercentage: 20,
        meetingsZone: [18, 24, 36, 42],
        callsPercentage: 16,
        callsZone: [39, 52, 78, 91],
        vibe: "positive",
        topSentimentUsers: [],
        reporteeCount: 4,
        progressType:
            "productive-hours",
        positiveSentimentUsersList: [
            {
                key: 'uuuuuuuu-uuuu-uuuu-uuuu-uuuuuuuclive',
                value
                    :
                {
                    overallMessageCount: 2, msgCount: 2, negativeMsgCount: 1, sentiment: 0.6, photo: '', designation: "team lead", displayName: "Clive Tucker",

                }
            }],
        positiveSentimentCount: 1,
        neutralSentimentUsersList: [],
        neutralSentimentCount: 0,
        negativeSentimentUsersList: [],
        lvTypeFocused: "Positive",
        lvTypeMeeting: "Negative",
        lvTypeMessages: "Neutral",
        focusedZone: [4, 5, 7, 8],
        focusedPercentage: 64,
        expectedMeetingsPercentage: 24,
        expectedFocusedPercentage: 16,
        expectedCallsPercentage: 70,
        categoryTag: [
            { label: 'Company and Vision', val: 2 },
            { label: 'Employee Satisfaction', val: 2 },
            { label: 'Team Dynamics', val: 1 },
            { label: 'Performance and Recognition', val: 3 },
        ],
        activities: []
    },
    {
        userName: "Murtagh Blethyn",
        userImage: null,
        userStatus: "Success",
        userDesignation: "software developer",
        userEmail: "usermurtagh.blethyn@countrymatters.co.uk",
        userGUID: "uuuuuuuu-uuuu-uuuu-uuuu-uuuuumurtagh",
        meetingsPercentage: 40,
        meetingsZone: [18, 24, 36, 42],
        callsPercentage: 6,
        callsZone: [39, 52, 78, 91],
        vibe: "positive",
        topSentimentUsers: [],
        reporteeCount: 4,
        progressType:
            "productive-hours",
        positiveSentimentUsersList: [
            {
                key: 'uuuuuuuu-uuuu-uuuu-uuuu-uuuuuuuclive',
                value
                    :
                {
                    overallMessageCount: 2, msgCount: 2, negativeMsgCount: 1, sentiment: 0.6, photo: '', designation: "team lead", displayName: "Clive Tucker",

                }
            }],
        positiveSentimentCount: 1,
        neutralSentimentUsersList: [],
        neutralSentimentCount: 0,
        negativeSentimentUsersList: [],
        lvTypeFocused: "Positive",
        lvTypeMeeting: "Negative",
        lvTypeMessages: "Neutral",
        focusedZone: [4, 5, 7, 8],
        focusedPercentage: 54,
        expectedMeetingsPercentage: 34,
        expectedFocusedPercentage: 15,
        expectedCallsPercentage: 60,
        categoryTag: [
            { label: 'Company and Vision', val: 2 },
            { label: 'Employee Satisfaction', val: 1 },
            { label: 'Team Dynamics', val: 5 },
            { label: 'Performance and Recognition', val: 3 },
        ],
        activities: []
    },
    {
        userName: "Clive Tucker",
        userImage: null,
        userStatus: "Success",
        userDesignation: "team lead",
        userGUID: "uuuuuuuu-uuuu-uuuu-uuuu-uuuuuuuclive",
        userEmail: "userclive.tucker@countrymatters.co.uk",
        meetingsPercentage: 30,
        meetingsZone: [18, 24, 36, 42],
        callsPercentage: 56,
        callsZone: [39, 52, 78, 91],
        vibe: "positive",
        topSentimentUsers: [],
        reporteeCount: 0,
        progressType:
            "productive-hours",
        positiveSentimentUsersList: [],
        positiveSentimentCount: 0,
        neutralSentimentUsersList: [],
        neutralSentimentCount: 0,
        negativeSentimentUsersList: [],
        lvTypeFocused: "Positive",
        lvTypeMeeting: "Negative",
        lvTypeMessages: "Neutral",
        focusedZone: [4, 5, 7, 8],
        focusedPercentage: 14,
        expectedMeetingsPercentage: 54,
        expectedFocusedPercentage: 19,
        expectedCallsPercentage: 30,
        categoryTag: [
            { label: 'Company and Vision', val: 2 },
            { label: 'Employee Satisfaction', val: 3 },
            { label: 'Team Dynamics', val: 1 },
            { label: 'Performance and Recognition', val: 1 },
        ],
        activities: []
    }
]
export const networkUsers = [
    {
        displayName: "Bob Fleming",
        userGUID: "uuuuuuuu-uuuu-uuuu-uuuu-uuuuuuuuubob",
        userPhoto: "",
        isManager: true
    },
    {
        displayName: "Jed Thomas",
        userGUID: "uuuuuuuu-uuuu-uuuu-uuuu-uuuuuuuuujed",
        userPhoto: "",
        isManager: false
    },
    {
        displayName: "Clarissa Wind",
        userGUID: "uuuuuuuu-uuuu-uuuu-uuuu-uuuuclarissa",
        userPhoto: "",
        isManager: false
    },
    {
        displayName: "Murtagh Blethyn",
        userGUID: "uuuuuuuu-uuuu-uuuu-uuuu-uuuuumurtagh",
        userPhoto: "",
        isManager: false
    },
    {
        displayName: "Clive Tucker",
        userGUID: "uuuuuuuu-uuuu-uuuu-uuuu-uuuuuuuclive",
        userPhoto: "",
        isManager: false
    },
]
export const loggedInUserGuidData = "uuuuuuuu-uuuu-uuuu-uuuu-uuuuuuuuubob"
export const WellBeingScoreDescriptions = ["Unavailable", "At Risk", "Struggling", "Neutral", "Satisfied", "Thriving"]
export const wellBeingCategories = [
    {
        id: 1, questionCategoryName: "Company and Vision", questionCategoryDesc: "Understand how well employees comprehend and align with the company mission, vision, and long-term goals.", isSelected: 1, tenantId: 0, isDeleted: false
    },
    {
        id: 2, questionCategoryName: "Employee Satisfaction", questionCategoryDesc: "Gauge overall job satisfaction, including aspects such as work-life balance, compensation, and workplace environment.", isSelected: 1, tenantId: 0, isDeleted: false
    },
    {
        id: 3, questionCategoryName: "Team Dynamics", questionCategoryDesc: "Assess the effectiveness of team collaboration, communication, and the overall sense of support and trust among team members.", isSelected: 1, tenantId: 0, isDeleted: false
    },
    {
        id: 4, questionCategoryName: "Leadership and Management", questionCategoryDesc: "Evaluate the effectiveness of leadership at all levels, including communication, support, decision-making, and feedback processes.", isSelected: 1, tenantId: 0, isDeleted: false
    },
    {
        id: 5, questionCategoryName: "Professional Development", questionCategoryDesc: "Examine the availability and quality of opportunities for employees to grow their skills and advance their careers within the company.", isSelected: 0, tenantId: 0, isDeleted: false
    },
    {
        id: 6, questionCategoryName: "Employee Engagement", questionCategoryDesc: "Measure the level of enthusiasm and commitment employees feel towards their work and the organization, including their motivation to contribute to company success.", isSelected: 0, tenantId: 0, isDeleted: false
    },
    {
        id: 7, questionCategoryName: "Performance and Recognition", questionCategoryDesc: "Assess the clarity and fairness of performance evaluations and the effectiveness of recognition programs in acknowledging employee achievements.", isSelected: 1, tenantId: 0, isDeleted: false
    },
    {
        id: 8, questionCategoryName: "Custom Category", questionCategoryDesc: "", isSelected: 0
    }
]

export const wellbeingFormDemoData = {
    id: 1, frequencyId: 4, frequency: { id: 4, header: '5 Questions Weekly', key: 4 }, groups:[], priorityId: "", categoriesId: "1,3,4,7,2", useAllQuestions: true, runForSelectedGroups: false, questionIdList: "", tenantId: 11, groupId: "", dateUpdated: "2025-01-23T14:25:03.813", updatedBy: "userbob.fleming@countrymatters.co.uk", isRunning: true }
