import axios, { AxiosInstance } from "axios";
import AuthHelper from "./auth-helper";
import { AuthenticationResult } from "@azure/msal-browser";
import * as constants from "../constants";

class FiltersApiService {
  static AuthenticatedApi = axios.create({
    baseURL: constants.Api.baseUri,
    // will inject auth header on-demand later as needed.
    headers: {
      "Content-Type": "application/json",
    },
  });

  // an api operation that calls Filter/GetOverviewData for a user api endpoint.
  static GetOverviewData(token) {
    return this.AuthenticatedApi.get("/Filter/GetOverviewData", {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(
          "FiltersApiService.GetOverviewData() - An error has occurred calling the web api: " +
          error
        );
        return null;
      });
  }
  //fetching list of cards in add card section
  static GetAddCards(token) {
    return this.AuthenticatedApi.get("/Filter/GetAddCards", {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(
          "FiltersApiService.GetAddCards() - An error has occurred calling the web api: " +
          error
        );
        return null;
      });
  }
  //updating pin card status
  static UpdatePinnedCard(cardWidgetID, token) {
    return this.AuthenticatedApi.post("/Filter/UpdatePinnedCard/", cardWidgetID, {
      params: {
        cardWidgetID: cardWidgetID,
      },
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(
          "FiltersApiService.UpdatePinnedCard() - An error has occurred calling the web api: " +
          error
        );
        return null;
      });
  }
  static GetSharedAllCardsList(dashboardParam) {
    return this.AuthenticatedApi.get("/Filter/GetSharedAllCardsList/?dID=" + dashboardParam)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(
          "FiltersApiService.GetSharedAllCardsList() - An error has occurred calling the web api: " +
          error
        );
        return null;
      });
  }
  // an api operation that calls Filter/GetUserData for a user api endpoint.
  static GetUserData(token) {
    return this.AuthenticatedApi.get("/Filter/GetUserData", {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(
          "FiltersApiService.GetUserData() - An error has occurred calling the web api: " +
          error
        );
        return null;
      });
  }

  static GetSharedUserData(dashboardParam) {
    return this.AuthenticatedApi.get("/Filter/GetSharedUserData/?dID=" + dashboardParam)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(
          "FiltersApiService.GetSharedUserData() - An error has occurred calling the web api: " +
          error
        );
        return null;
      });
  }

  static AddCardWithFilters(cardFilterData, token) {
    return this.AuthenticatedApi.post("/Filter/InsertCard/", cardFilterData, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        console.log("Card added successfully.");
        localStorage.removeItem('layout' + cardFilterData.dashboardId);
        return response;
      })
      .catch((error) => {
        console.log(
          "FiltersApiService.AddCardWithFilters() - An error has occurred calling the web api: " +
          error
        );
        return -1;
      });
  }
  static AddCardWithMultipleFilters(cardsFilterData, token) {
    return this.AuthenticatedApi.post(
      "/Filter/InsertMultiSelectCard/",
      cardsFilterData,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    )
      .then((response) => {
        console.log("Cards added successfully.");
        return response;
      })
      .catch((error) => {
        console.log(
          "FiltersApiService.AddCardWithMultipleFilters() - An error has occurred calling the web api: " +
          error
        );
        return null;
      });
  }

  static GetCardLivePreview(cardId, selectedDate, cardFilterData, token) {
    let cardLivePreviewData = {
      cardId: cardId,
      selectedDate: selectedDate,
      cardFilter: cardFilterData
    };
    return this.AuthenticatedApi.post("/Filter/GetCardLivePreview/", cardLivePreviewData, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(
          "FiltersApiService.GetCardLivePreview() - An error has occurred calling the web api: " +
          error
        );
        return null;
      });
  }

  static UpdateCardWithFilters(cardFilterData, token) {
    return this.AuthenticatedApi.put("/Filter/UpdateCard/", cardFilterData, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        console.log("Card updated successfully.");
        return response;
      })
      .catch((error) => {
        console.log(
          "FiltersApiService.UpdateCardWithFilters() - An error has occurred calling the web api: " +
          error
        );
        return null;
      });
  }

  static UpdateMultiCardWithFilters(cardFilterData, token) {
    return this.AuthenticatedApi.put("/Filter/UpdateMultiCard/", cardFilterData, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        console.log("Cards updated successfully.");
        return response;
      })
      .catch((error) => {
        console.log(
          "FiltersApiService.UpdateMultiCardWithFilters() - An error has occurred calling the web api: " +
          error
        );
        return null;
      });
  }

  // an api operation that calls Filter/GetUserData for a user api endpoint.
  static GetCardData(cardId, token) {
    return this.AuthenticatedApi.get("/Filter/GetCard/" + cardId, {
      params: {
        cardId: cardId,
      },
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(
          "FiltersApiService.GetCardData() - An error has occurred calling the web api: " +
          error
        );
        return null;
      });
  }

  static GetSharedCardData(cardUrl) {
    return this.AuthenticatedApi.get("/Filter/GetSharedCard?cID=" + cardUrl)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(
          "FiltersApiService.GetSharedCardData() - An error has occurred calling the web api: " +
          error
        );
        return null;
      });
  }

  static GetSharedCardDetails(cardUrl) {
    return this.AuthenticatedApi.get("/Filter/GetSharedCardDetails?cID=" + cardUrl)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(
          "FiltersApiService.GetSharedCardDetails() - An error has occurred calling the web api: " +
          error
        );
        return null;
      });
  }

    static GetCardCSVData(cardUrl) {
        return this.AuthenticatedApi.get("/Filter/GetCardDataAsCSV?cID=" + cardUrl)
          .then((response) => {
            return response.data;
          })
          .catch((error) => {
            console.log(
              "FiltersApiService.GetCardDataAsCSV() - An error has occurred calling the web api: " +
              error
            );
            return null;
          });
    }

    static async GetCardDataAndFilters(cardUrl) {
        try {
            const response = await this.AuthenticatedApi.get(`/Filter/GetCardDataAndFilters?cID=${cardUrl}`);
            return response.data;
        } catch (error) {
            console.error(`FiltersApiService.GetCardDataAndFilters() - An error has occurred calling the web API: ${error.message}`);
            return null;
        }
    }

  // static FormatDate(date) {
  
  //   var d = date,
  //     month = "" + (d.getMonth() + 1),
  //     day = "" + d.getDate(),
  //     year = d.getFullYear();

  //   if (month.length < 2) month = "0" + month;
  //   if (day.length < 2) day = "0" + day;

  //   return [year, month, day].join("-");
  // }

  static DeleteCard(dashboardId, cardId, deletecardwidgetId, token) {
    return this.AuthenticatedApi.delete("/Filter/DeleteCard/" + cardId, {
      params: {
        cardId: cardId,
        dashboardId: dashboardId,
        deletecardwidgetId: deletecardwidgetId,
      },
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(
          "FiltersApiService.DeleteCard() - An error has occurred calling the web api: " +
          error
        );
        return null;
      });
    }
    static CardAPICallForChannel(filters, teamName, token) {
        let channelData = {
            filters: filters,
            teamName: teamName
        };
        return this.AuthenticatedApi.post("/Filter/CardAPICallForChannel/", channelData , {
          headers: {
                Authorization: "Bearer " + token,
            },
        })
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                console.log(
                    "FiltersApiService.CardApiCall() - An error has occurred calling the web api: " +
                    error
                );
                return null;
            });
    }
}

export default FiltersApiService;
