import WindowContext from "../../Shared/Context/Context";
import "./TeamSummaryView.scss";
import { WindowMaximizeIcon } from "@fluentui/react-icons-northstar";

const TeamSummaryView = ({ data }) => {
  return (
      <WindowContext.Consumer>{
      (context) => (


        <div className="activity-view">
          <div className="smryDiv" style={{ flexDirection: `${context.mediaCategory.sm ? "column" : "row"}` }}>
            <div className={context.mediaCategory.sm ? " " : "smrL cardW-half"}>
              <div className="smrL-label">
                <div className="mainLabelRow">
                  <div className="mainLabel">
                    <div className="mainLabel-p">
                      Team Name
                    </div>
                    <div className="mainLabel-val">{data.groupName}</div>
                  </div>
                </div>
                <div className="mainLabelRow">
                  <div className="mainLabel">
                    <div className="mainLabel-p">
                      <i className="fs-20 bi bi-dot"></i> Total Channels
                    </div>
                    <div className="mainLabel-val">{data.totalChannels}</div>
                  </div>
                </div>
                <div className="mainLabelRow">
                  <div className="mainLabel">
                    <div className="mainLabel-p">
                      <i className="fs-20 bi bi-dot"></i> {/* <WindowMaximizeIcon /> */} Membership
                    </div>
                    <div className="mainLabel-val">{data.totalUsers}</div>
                  </div>
                  <div className="subLabel">
                    <div className="subLabelRow">
                      <div className="mainLabel-p">
                        <i className="fs-20 bi bi-dot"></i> Members
                      </div>
                      <div className="mainLabel-val">{data.memberGuests}</div>
                    </div>
                    <div className="subLabelRow">
                      <div className="mainLabel-p">
                        <i className="fs-20 bi bi-dot"></i> Owners
                      </div>
                      <div className="mainLabel-val">{data.owners}</div>
                    </div>
                  </div>
                </div>
                <div className="mainLabelRow">
                  <div className="mainLabel">
                    <div className="mainLabel-p">
                      <i className="fs-20 bi bi-dot"></i> Active Members (Owners)
                    </div>
                    <div className="mainLabel-val">{data.activeOwnersMembers}</div>
                  </div>
                </div>
                <div className="mainLabelRow">
                  <div className="mainLabel">
                    <div className="mainLabel-p">
                      <i className="fs-20 bi bi-dot"></i> Inactive Members (Owners)
                    </div>
                    <div className="mainLabel-val">{data.inactiveOwnersMembers}</div>
                  </div>
                </div>
                <div className="mainLabelRow">
                  <div className="mainLabel">
                    <div className="mainLabel-p">
                      <i className="fs-20 bi bi-dot"></i> External Members
                    </div>
                    <div className="mainLabel-val">{data.externalMembers}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className={context.mediaCategory.sm ? "bg-smrR " : "smrR cardW-half"}>
              <div className="smrR-top">
                <div className="topTile">
                  <div className="topTile-p">Total Meetings (External)</div>
                  <div className="topTile-val">{data.totalMeetings}</div>
                </div>
                <div className="topTile">
                  <div className="topTile-p">Total Posts & Replies (External)</div>
                  <div className="topTile-val">{data.totalMessages}</div>
                </div>

              </div>
              <div className="smrR-top">
                <div className="topTile">
                  <div className="topTile-p">Mentions (External)</div>
                  <div className="topTile-val">{data.totalMentions}</div>
                </div>
                <div className="topTile">
                  <div className="topTile-p">Reactions (External)</div>
                  <div className="topTile-val">{data.totalReactions}</div>
                </div>

              </div>
              <div className="smrBott">
                <div className="smrR-latestAct">
                  <div className="latestActTitle">Last Activity</div>
                  <div className="latestActData">
                    <div className="latestActDataTime">
                      : {data.latestActivityTime}
                    </div>
                    <div>&nbsp;</div>
                    <div className="latestActDataH">
                      {data.latestActivitySubject}
                    </div>
                  </div>
                </div>
                <div className="smrR-nextAct">
                  {/* <div className="nextActTitle">Next Meeting </div> */}
                  <div className="nextActData">Next Meeting
                    <div className="nextActDataRow">
                      <div className="nextActDataRow-p">Subject</div>
                      <div className="nextActDataRow-h">
                        : {data.nextMeetingSubject}
                      </div>
                    </div>
                    <div className="nextActDataRow">
                      <div className="nextActDataRow-p">Time</div>
                      <div className="nextActDataRow-h"> : {data.nextMeetingTime}</div>
                    </div>
                    <div className="nextActDataRow">
                      <div className="nextActDataRow-p">Organiser </div>
                      <div className="nextActDataRow-h">
                        : {data.nextMeetingOrganiser}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }
    </WindowContext.Consumer>
  );
};

export default TeamSummaryView;
