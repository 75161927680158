import React from "react";
import { useState, useEffect } from "react";
import {
  Flex,
  Box,
  Checkbox,
  Button,
  Text,
  Divider,
  Dropdown,
  Avatar,
  Tooltip,
  Input,
  Loader,
  Popup,
} from "@fluentui/react-northstar";
import { Dialog } from "@progress/kendo-react-dialogs";
import {
  Grid,
  GridColumn as Column,
  GridNoRecords,
} from "@progress/kendo-react-grid";
import { orderBy } from "@progress/kendo-data-query";
import {
  TeamCreateIcon,
  SearchIcon,
  PresenceAvailableIcon,
  ExclamationTriangleIcon,
  ShiftActivityIcon,
  InfoIcon,
} from "@fluentui/react-icons-northstar";
import "../Dashboard/dashboard.scss";
import "./config.scss";
import ConfigurationApiService from "../../../services/ConfigurationApiService";
import AuthHelper from "../../../services/auth-helper";
import useMediaQuery from "../../Shared/CustomHooks/useMediaQuery";
import WindowContext from "../../Shared/Context/Context";
import { GroupInheritanceIcon, TrialExpiredIcon, LicenseWarningIcon } from "./commonIcons";
import { OPERATIONSTATUS } from "../../Shared/uicomponents/OperationStatus/index";
import { Error403 } from "../../Shared/Error/403";
import { Error500 } from "../../Shared/Error/500";

const administrativeAccessTypes = [
  { id: 0, header: 'No Admin', key: 0 },
  { id: 2, header: 'Admin', key: 2 },
  { id: 1, header: 'Super Admin', key: 1 }


];
const administrativeAccessTypesTeamOwner = [
  { id: 0, header: 'No Admin', key: 0 },
  { id: 2, header: 'Admin', key: 2 }
];


const HeaderCell = (props) => {
  return (
    <a className="k-link" onClick={props.onClick}>
      <span>
        {props.title}
      </span>
      {props.children != null && props.children[0]}
    </a>
  );
};

const CellWithUserImage = (props) => {
  const user = props.dataItem;
  return (
    <WindowContext.Consumer>
      {
        (context) => (
          <td style={{ ...props.style }} className={props.className}>
            <Avatar
              name={user.userName}
              image={user.userImage}
              size={context.mediaCategory.sm ? "small" : "large"}
            />
          </td>
        )
      }
    </WindowContext.Consumer>
  );
};

const CellWithUserName = (props) => {
  const user = props.dataItem;
  return (
    <td style={{ ...props.style }} className={props.className}>
      <Box className="textOverFlow">
        <Text content={user.userName} size="medium" />
      </Box>
      <Text content={user.jobTitle} size="small" timestamp />
    </td>
  );
};

const CellWithAdministrativeAccess = (props) => {

  const user = props.dataItem;
  const [administrativeAccessLoading, setAdministrativeAccessLoading] = useState(false);
  const [administrativeAccess, setAdministrativeAccess] = useState(null);

  useEffect(() => {
    let item = administrativeAccessTypes.find(e => e.id === user.administrativeAccess);
    setAdministrativeAccess(item);
  }, [props]);

  const [isDemo, setIsDemo] = useState(false);
  const [isDemoMode, setIsDemoMode] = useState(global.localStorage.getItem("demoGlobal"));

  useEffect(() => {
    if (isDemoMode === "true") {
      setIsDemo(true);
    } else {
      setIsDemo(false);
      }
      props.viewStatus.setPageStatus(null);
      props.viewStatus.InformChildPageStatus(null);
  }, []);


  const handleAdministrativeAccess = {
    onAdd: (item) => {
      if (item.id === user.administrativeAccess) {
        return;
      }
      setAdministrativeAccessLoading(true);
      var data = {
        userGUIDList: [user.userGUID],
        accessID: item.id,
      };
      AuthHelper.getAccessToken(function (token) {
        ConfigurationApiService.UpdateAdministrativeAccess(data, token).then(
          (response) => {
            if (response  && response.status == 200) {
              props.handleUserAdministrativeAccess(item.id, user);
              setAdministrativeAccess(item);
                props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Success);
            }
            else if (response.status == 401)
                props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Unauthorized);
            else
                props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
            setAdministrativeAccessLoading(false);
          }
        );
      });
    }
  };

  const renderAdministrativeAccess = () => {

    if (user.email == props.loggedInUserEmail && (user.administrativeAccess === 1 || (user.administrativeAccessInherited !== 1 && user.administrativeAccess === 2)
      || (user.administrativeAccessInherited !== 1 && user.administrativeAccessInherited !== 2 && user.administrativeAccess === 0))) {
      let access = administrativeAccessTypes.find(e => e.id === user.administrativeAccess);
      return <>
        <Tooltip
          className="tooltipTxt"
          trigger={
            <Text weight="bold" styles={{ color: "#A4A4A4" }}>{access.header}</Text>
          }
          align="center"
          position="below"
          content={"Edit permission disabled for own account"}
        />
      </>
    }
    else if (!props.isLoggedInUserAdmin && user.administrativeAccess === 1) {
      return <>
        <Tooltip
          className="tooltipTxt"
          trigger={
            <Text weight="bold" styles={{ color: "#A4A4A4" }}>Super Admin </Text>
          }
          align="center"
          position="below"
          content={"Admin can not change Super Admin permissions"}
        />
      </>
    }
    else if (user.administrativeAccess === 1 || (user.administrativeAccessInherited !== 1 && user.administrativeAccess === 2)
      || (user.administrativeAccessInherited !== 1 && user.administrativeAccessInherited !== 2 && user.administrativeAccess === 0)) {

      return <Dropdown
        inline
        items={props.isLoggedInUserAdmin ? administrativeAccessTypes : administrativeAccessTypesTeamOwner}
        value={administrativeAccess}
        getA11ySelectionMessage={handleAdministrativeAccess}
        disabled={isDemo === true}
        itemToValue={(obj) => {
          return obj.key;
        }}
      />
    }
    else if (user.administrativeAccessInherited === 1) {
      return <>
        <Text weight="bold" styles={{ marginRight: "20px" }}>Super Admin </Text>
        <GroupInheritanceIcon colorValue={"green"} userGUID={user.userGUID} inheritanceType={"administrative"} />
      </>
    }
    else if (user.administrativeAccessInherited === 2) {
      return <>
        <Text weight="bold" styles={{ marginRight: "15px" }}>Admin </Text>
        <GroupInheritanceIcon colorValue={"green"} userGUID={user.userGUID} inheritanceType={"administrative"} />
      </>
    }
    else {
      return <LicenseWarningIcon type={"group"} />
    }
  }

  return (
    <td style={{ overflow: "visible" }}>
      {administrativeAccessLoading ? (
        <Loader size="small" inline />
      ) : (renderAdministrativeAccess())
      }
    </td>
  );
};

const CellWithReportingAccess = (props) => {
  const user = props.dataItem;
  const [reportingAccessLoading, setReportingAccessLoading] = useState(false);

  const [isDemo, setIsDemo] = useState(false);
  const [isDemoMode, setIsDemoMode] = useState(global.localStorage.getItem("demoGlobal"));

  useEffect(() => {
    if (isDemoMode === "true") {
      setIsDemo(true);
    } else {
      setIsDemo(false);
      }
      props.viewStatus.setPageStatus(null);
      props.viewStatus.InformChildPageStatus(null);
  }, []);


  const handleReportingAccess = (isChecked) => {
    setReportingAccessLoading(true);
    var data = {
      userGUIDList: [user.userGUID],
      accessID: isChecked.checked ? 1 : 0,
    };
    AuthHelper.getAccessToken(function (token) {
      ConfigurationApiService.UpdateReportingAccess(data, token).then(
        (response) => {
          if (response && response.status == 200) {
            props.handleUserReportingAccess(isChecked.checked ? 1 : 0, user);
              props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Success);
          }
          else if (response.status == 401)
              props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Unauthorized);
          else
              props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
          setReportingAccessLoading(false);
        }
      );
    });
  };

  return (
    <td style={{ paddingLeft: "20px" }}>
      {reportingAccessLoading ? (
        <Loader size="small" inline />
      ) : (
        user.reportingAccess !== 1 && user.reportingAccessInherited !== 0 ? (
          user.reportingAccessInherited === 1 ?
            <Box style={{ marginLeft: "10px" }}>
              <GroupInheritanceIcon colorValue={"green"} userGUID={user.userGUID} inheritanceType={"reporting"} />
            </Box>
            :
            <Box style={{ marginLeft: "10px" }}>
              <LicenseWarningIcon type={"group"} />
            </Box>
        ) : (
          <Checkbox
            toggle
            checked={user.reportingAccess === 1}
            disabled={isDemo === true}
            onClick={(event, isChecked) => {
              handleReportingAccess(isChecked);
            }}
          />
        )
      )}
    </td>
  );
};

const CellWithSubscription = (props) => {
  const user = props.dataItem;
  const [subscriptionLoading, setSubscriptionLoading] = useState(false);
  const [subscribedProduct, setSubscribedProduct] = useState(null);

  const [isDemo, setIsDemo] = useState(false);
  const [isDemoMode, setIsDemoMode] = useState(global.localStorage.getItem("demoGlobal"));

  useEffect(() => {
    if (isDemoMode === "true") {
      setIsDemo(true);
    } else {
      setIsDemo(false);
      }
      props.viewStatus.setPageStatus(null);
      props.viewStatus.InformChildPageStatus(null);
  }, []);

  useEffect(() => {
    let item = props.productTypes.find(e => e.id === user.subscriptionId);
    setSubscribedProduct(item);
  }, [props])

  const handleUserSubscription = {
    onAdd: (item) => {
      if (item.id === user.subscriptionId || user.email === props.loggedInUserEmail
        || (!props.tenantDetails.autoBuySubscription && item.disabled)) {
        return;
      }
      setSubscriptionLoading(true);
      var data = {
        userGUIDList: [user.userGUID],
        accessID: item.id,
      };
      AuthHelper.getAccessToken(function (token) {
        ConfigurationApiService.UpdateUserSubscription(data, token).then(
          (response) => {
            if (response && response.status == 200) {
              props.handleUserSubscription(item.id, user);
                setSubscribedProduct(item);
                props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Success);
            }
            else if (response.status == 401)
                props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Unauthorized);
            else
                props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
            setSubscriptionLoading(false);
          }
        );
      });
    }
  };

  const renderUserSubscription = () => {
    let item = props.productTypes.find(e => e.id === user.subscriptionId);
    let itemInherited = props.productTypes.find(e => e.id === user.subscriptionIdInherited % 500);
    let productName = user.isProductInherited ? itemInherited?.header : item?.header;

    if (user.email === props.loggedInUserEmail) {
      return <>
        <Flex space="between">
          <Tooltip
            className="tooltipTxt"
            trigger={
              <Text weight="bold" styles={{ color: "#A4A4A4" }}>
                {user.isProductInherited ? itemInherited?.header : item?.header}
              </Text>
            }
            align="center"
            position="below"
            content={<div>
              <p style={{ marginBottom: "0px", weight: "bold" }}>{productName}</p>
              {!user.isProductInherited && ((user.email === props.loggedInUserEmail) ? "Edit permission disabled for own account"
                : "Admin can not change Super Admin permissions")}
            </div>
            }
          />
          <Flex gap="gap.small">
            {user.isProductInherited && Math.floor(user.subscriptionIdInherited / 500) === 0 &&
              <GroupInheritanceIcon colorValue={"#A4A4A4"} userGUID={user.userGUID} inheritanceType={"subscription"} />
            }
            {user.isProductInherited && Math.floor(user.subscriptionIdInherited / 500) !== 0 &&
              <LicenseWarningIcon colorValue={"#A4A4A4"} type={"group"} />
            }
          </Flex>
        </Flex>
      </>
    }
    else {
      if (!user.isProductInherited) {
        return <Tooltip
          className="tooltipTxt"
          trigger={
            <Dropdown
              style={{ maxWidth: "unset", width: "auto" }}
              inline
              items={props.productTypes}
              value={subscribedProduct}
              getA11ySelectionMessage={handleUserSubscription}
              disabled={isDemo === true}
              itemToValue={(obj) => {
                return obj.key;
              }}
              renderItem={(Component, props) => {
                if (props.disabled) {
                  props.className = props.className + " _licenseUnavailable";
                }
                return <Component {...props}></Component>;
              }}
            />
          }
          align="center"
          position="before"
          content={item?.header}
        />
      }
      else {
        return <>
          <Flex space="between">
            <Tooltip
              className="tooltipTxt"
              trigger={<Text weight="bold" content={itemInherited?.header} truncated />}
              align="center"
              position="before"
              content={itemInherited?.header}
            />
            <Flex gap="gap.small">
              {Math.floor(user.subscriptionIdInherited / 500) === 0 &&
                <GroupInheritanceIcon colorValue={"green"} userGUID={user.userGUID} inheritanceType={"subscription"} />
              }
              {Math.floor(user.subscriptionIdInherited / 500) !== 0 &&
                <LicenseWarningIcon colorValue="orange" type={"group"} />
              }
            </Flex>
          </Flex>
        </>
      }
    }
  }

  return (
    <td style={{ overflow: "visible" }} className="subscriptionDropDown">
      {subscriptionLoading ? (
        <Loader size="small" inline />
      ) : (renderUserSubscription())}
    </td>
  );
};

const ListDetailComponent = (props) => {
  return (
    <section style={{ paddingRight: "32px" }}>
      <table>
        <tr>
          <td>Administration Access:</td>

          <CellWithAdministrativeAccess {...props} />
        </tr>
        <tr>
          <td>Reporting Access:</td>
          <CellWithReportingAccess {...props} />
        </tr>
        <tr>
          <td>Subscription:</td>
          <CellWithSubscription {...props} />
        </tr>
      </table>
    </section>
  );
};


const PeopleList = (props) => {
  const [sort, setSort] = useState([]);
  const [userData, setUserData] = useState(null);
  const [tenantDetails, setTenantDetails] = useState(null);
  const [loggedInUserEmail, setLoggedInUserEmail] = useState(null);
  const [productTypes, setProductTypes] = useState([]);
  const [productConsumed, setproductConsumed] = useState([]);

  const [groupAdministrativeAccess, setGroupAdministrativeAccess] = useState(0);
  const [groupReportingAccess, setGroupReportingAccess] = useState(0);
  const [groupSubscription, setGroupSubscription] = useState(0);
  const [groupProductTypes, setGroupProductTypes] = useState([]);

  const [isDemo, setIsDemo] = useState(false);
  const [isDemoMode, setIsDemoMode] = useState(global.localStorage.getItem("demoGlobal"));

  useEffect(() => {
    if (isDemoMode === "true") {
      setIsDemo(true);
    } else {
      setIsDemo(false);
      }

  }, []);


  useEffect(() => {
    setTenantDetails(props.tenantDetails);
    setLoggedInUserEmail(props.loggedInUserEmail);
    let _administrativeAccess = administrativeAccessTypes.find(e => e.id === props.selectedGroupDetail.administrativeAccess);
    setGroupAdministrativeAccess(_administrativeAccess);
    setGroupReportingAccess(props.selectedGroupDetail.reportingAccess === 1);
      props.viewStatus.setPageStatus(null);
      props.viewStatus.InformChildPageStatus(null);
    loadUserData();
  }, [props.selectedGroupDetail]);

  const expandChange = (event) => {
    let newData = userData.map((item) => {
      if (item.userName === event.dataItem.userName) {
        item.expanded = !event.dataItem.expanded;
      }
      return item;
    });
    setUserData(newData);
  };

  const loadUserData = () => {
    AuthHelper.getAccessToken(function (token) {
      ConfigurationApiService.GetUsersByGroupId(props.selectedGroupDetail.groupId, token).then(
        (response) => {
          if (response != undefined && response != "") {
            props.handleMemberCount(response.users.length);
            //  handleValidations(response.totalSubscribed, response.users, response.isGroupTrialExpired);
            setUserSubscriptionData(response.users, response.planQuantityConsumed);
            setproductConsumed(response.planQuantityConsumed);
          } else {
            setUserData([]);
            props.handleMemberCount(0);
          }
        }
      );
    });
  };

  const sortChange = (event) => {
    if (event.sort.length === 0) {
      setSort(event.sort);
      return;
    }
    var sortOrder = event.sort[0].dir;
    var columnName = event.sort[0].field;
    if (columnName === "administrativeAccess") {
      let sortArray = [{ field: 'administrativeAccess', dir: sortOrder },
      { field: 'administrativeAccessInherited', dir: sortOrder }];
      setSort(sortArray);
    } else
      if (columnName === "reportingAccess") {
        let sortArray = [{ field: 'reportingAccess', dir: sortOrder },
        { field: 'reportingAccessInherited', dir: sortOrder }];
        setSort(sortArray);
      } else
        if (columnName === "subscriptionId") {
          let sortArray = [{ field: 'subscriptionId', dir: sortOrder },
          { field: 'subscriptionIdInherited', dir: sortOrder }];
          setSort(sortArray);
        } else {
          setSort(event.sort);
        }
  };

  const setUserSubscriptionData = (users, planQuantityConsumed) => {
    var _products = props.tenantDetails.plans.map(e => { return { ...e, availableUser: 0 } });
    var _users = users.map(user => {
      var subscriptionRank = _products.find(e => e.productId === user.subscriptionId)?.productRank;
      var subscriptionInheritedRank = _products.find(e => e.productId === user.subscriptionIdInherited)?.productRank;
      var consumedProductId = 0;
      var isProductInherited = false;
      if (!user.isGroupInheritanceAllowed) {
        consumedProductId = user.subscriptionId;
        isProductInherited = false;
      }
      else if (subscriptionRank > subscriptionInheritedRank) {
        consumedProductId = user.subscriptionId;
        isProductInherited = false;
      }
      else if (subscriptionRank < subscriptionInheritedRank) {
        consumedProductId = user.subscriptionIdInherited;
        isProductInherited = true;
      }
      else if (subscriptionRank === subscriptionInheritedRank) {
        consumedProductId = Math.max(user.subscriptionId, user.subscriptionIdInherited);
        isProductInherited = user.subscriptionId < user.subscriptionIdInherited
      }
      else if (user.subscriptionIdInherited > 500 && user.isGroupInheritanceAllowed) {
        isProductInherited = true;
      }

      _products.forEach((p, index, arr) => {
        if (p.productId !== consumedProductId) {
          arr[index].availableUser += 1;
        }
      })
      return { ...user, isProductInherited: isProductInherited };
    });

    var products = props.tenantDetails.plans.map(ele => {
      var consumed = 0;
      var pIndex = planQuantityConsumed.findIndex(e => e.productId === ele.productId);
      if (pIndex > -1) {
        consumed = planQuantityConsumed[pIndex].quantity;
      }
      return {
        id: ele.productId,
        header: ele.name,
        key: ele.productId,
        disabled: (!props.tenantDetails.autoBuySubscription && (ele.quantity !== -1) && (consumed >= ele.quantity)),
        content: (!props.tenantDetails.autoBuySubscription && (ele.quantity !== -1) && (consumed >= ele.quantity)) &&
          ("Maximum " + ele.quantity + " people can have " + ele.name)
      }
    });
    var groupProducts = props.tenantDetails.plans.map(ele => {
      var consumed = 0;
      var available = 0;
      var pIndex = planQuantityConsumed.findIndex(e => e.productId === ele.productId);
      if (pIndex > -1) {
        consumed = planQuantityConsumed[pIndex].quantity;
      }
      var pIndexAvl = _products.findIndex(e => e.productId === ele.productId);
      if (pIndexAvl > -1) {
        available = _products[pIndexAvl].availableUser;
      }

      console.log(ele.name + "  " + consumed + "  " + available);
      return {
        id: ele.productId,
        header: ele.name,
        key: ele.productId,
        disabled: (!props.tenantDetails.autoBuySubscription && (ele.quantity !== -1) && (available > ele.quantity - consumed)),
        content: (!props.tenantDetails.autoBuySubscription && (ele.quantity !== -1) && (available > ele.quantity - consumed)) &&
          ("Maximum " + ele.quantity + " people can have " + ele.name)
      }
    });
    console.log(planQuantityConsumed);
    let _groupSubscription = groupProducts.find(e => e.id === props.selectedGroupDetail.subscriptionId);
    setGroupSubscription(_groupSubscription);
    setProductTypes(products);
    setGroupProductTypes(groupProducts)
    setUserData(_users);
  }


  const handleUserAdministrativeAccess = (accessId, user) => {
    var tempUserdata = [...userData];
    var indexId = tempUserdata.findIndex((x) => x.userGUID === user.userGUID);
    tempUserdata[indexId].administrativeAccess = accessId;
    setUserData(tempUserdata);
  };

  const handleUserReportingAccess = (accessId, user) => {
    var tempUserdata = [...userData];
    var indexId = tempUserdata.findIndex((x) => x.userGUID === user.userGUID);
    tempUserdata[indexId].reportingAccess = accessId;
    setUserData(tempUserdata);
  };

  const handleUserSubscription = (accessId, user) => {
    loadUserData();
    // var tempUserdata = [...userData];
    // var indexId = tempUserdata.findIndex((x) => x.userGUID === user.userGUID);
    // tempUserdata[indexId].subscriptionId = accessId;
    // setUserData(tempUserdata);
  };


  const handleGroupAdministrativeAccess = {
    onAdd: (item) => {
      let accessId = item.id;
      var tempUserdata = [...userData];
      setUserData(null);
      var data = {
        groupId: props.selectedGroupDetail.groupId,
        accessID: accessId,
      };
      AuthHelper.getAccessToken(function (token) {
        ConfigurationApiService.UpdateGroupAdministrativeAccess(data, token).then(
          (response) => {
                if (response && response.status == 200) {
                    props.handleGroupPermissions(accessId, groupReportingAccess ? 1 : 0, groupSubscription.id);
                    setGroupAdministrativeAccess(item);
                    loadUserData();
                    props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Success);
                }
                else if (response.status == 401) {
                    setUserData(tempUserdata);
                    props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Unauthorized);

                }
                else {
                    setUserData(tempUserdata);
                    props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
                }
                    
          }
        );
      });
    }
  };

  const handleGroupReportingAccess = (isChecked) => {
    let accessId = isChecked.checked ? 1 : 0;
    var tempUserdata = [...userData];
    setUserData(null);
    var data = {
      groupId: props.selectedGroupDetail.groupId,
      accessID: accessId,
    };
    AuthHelper.getAccessToken(function (token) {
      ConfigurationApiService.UpdateGroupReportingAccess(data, token).then(
        (response) => {
          if (response && response.status == 200) {
            props.handleGroupPermissions(groupAdministrativeAccess.id, accessId, groupSubscription.id);
            setGroupReportingAccess(isChecked.checked);
            loadUserData();
              props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Success);
          }
          else if (response.status == 401) {
              setUserData(tempUserdata);
              props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Unauthorized);

          }
          else {
              setUserData(tempUserdata);
              props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
          }
        }
      );
    });
  };

  const handleGroupSubscription = {
    onAdd: (item) => {
      let accessId = item.id;
      var tempUserdata = [...userData];
      setUserData(null);
      var data = {
        groupId: props.selectedGroupDetail.groupId,
        accessID: accessId
      };
      AuthHelper.getAccessToken(function (token) {
        ConfigurationApiService.UpdateGroupSubscription(data, token).then(
          (response) => {
                if (response && response.status == 200) {

              props.handleGroupPermissions(groupAdministrativeAccess.id, groupReportingAccess ? 1 : 0, accessId);
              setGroupSubscription(item);
              loadUserData();
                props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Success);
            }
            else if (response.status == 401) {
                setUserData(tempUserdata);
                props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Unauthorized);

            }
            else {
                setUserData(tempUserdata);
                props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
            }
          }
        );
      });
    }
  };


  const CustomCellWithUserImage = (props) => (
    <CellWithUserImage {...props} />
  );

  const CustomCellWithAdministrativeAccess = (propsData) => (
    <CellWithAdministrativeAccess
      {...propsData}
      loggedInUserEmail={loggedInUserEmail}
      isLoggedInUserAdmin={props.isLoggedInUserAdmin}
      isLoggedInUserTeamOwner={props.isLoggedInUserTeamOwner}
      handleUserAdministrativeAccess={handleUserAdministrativeAccess}
      viewStatus={props.viewStatus}
    />
  );

  const CustomCellWithReportingAccess = (propsData) => (
    <CellWithReportingAccess
      {...propsData}
      isLoggedInUserAdmin={props.isLoggedInUserAdmin}
      isLoggedInUserTeamOwner={props.isLoggedInUserTeamOwner}
      handleUserReportingAccess={handleUserReportingAccess}
      viewStatus={props.viewStatus}
    />
  );

  const CustomCellWithSubscription = (propsData) => (
    <CellWithSubscription
      {...propsData}
      tenantDetails={tenantDetails}
      productTypes={productTypes}
      loggedInUserEmail={loggedInUserEmail}
      isLoggedInUserAdmin={props.isLoggedInUserAdmin}
      isLoggedInUserTeamOwner={props.isLoggedInUserTeamOwner}
      handleUserSubscription={handleUserSubscription}
      viewStatus={props.viewStatus}
    />
  );

    const CustomListDetailedComponent = (propsData) => (
        <ListDetailComponent
            {...propsData}
            tenantDetails={tenantDetails}
            productTypes={productTypes}
            loggedInUserEmail={loggedInUserEmail}
            isLoggedInUserAdmin={props.isLoggedInUserAdmin}
            isLoggedInUserTeamOwner={props.isLoggedInUserTeamOwner}
            handleUserAdministrativeAccess={handleUserAdministrativeAccess}
            handleUserReportingAccess={handleUserReportingAccess}
            handleUserSubscription={handleUserSubscription}
            viewStatus={props.viewStatus}
    />
  );

  return (
    <WindowContext.Consumer>
      {
        (context) => (
          <Box style={{ height: "100%" }}>
            {userData != null && userData.length > 0 && (
              <>
                <Flex className="justify-content-between flex-wrap" column={context.mediaCategory.sm} style={{ width: "100%", gap: "4px" }}>
                  <Box className={!context.mediaCategory.sm ? "d-flex text-nowrap align-items-center" : ""} style={{ width: "auto" }}>
                    <Text weight="bold" styles={{ display: "inline-block", marginRight: "10px" }}>Administration Access : </Text>

                    <Dropdown
                      styles={{ display: "inline-block", width: "auto", minWidth: "116px", marginRight: "10px" }}
                      fluid
                      items={props.isLoggedInUserAdmin ? administrativeAccessTypes : administrativeAccessTypesTeamOwner}
                      value={groupAdministrativeAccess}
                      getA11ySelectionMessage={handleGroupAdministrativeAccess}
                      disabled={(!props.isLoggedInUserAdmin && groupAdministrativeAccess.id === 1) || isDemo === true}
                      itemToValue={(obj) => {
                        return obj.key;
                      }}
                    />
                    <Popup
                      trigger={<InfoIcon outline size="large" />}
                      content={<>
                        <p><b>Super Admin :</b> can manage settings for <br />the whole organization. <br />
                          <b>Admin :</b> can manage settings of <br />teams owned by this user. <br />
                          <b>No Admin :</b> no access to settings.
                        </p>
                      </>}
                      pointing
                      trapFocus
                      position={"after"}
                      align={"center"}
                      on="hover"
                    />
                  </Box>
                  <Box styles={{ width: "auto" }} className={!context.mediaCategory.sm ? "d-flex text-nowrap align-items-center" : ""}>
                    <Text weight="bold">Reporting Access : </Text>
                    <Checkbox
                      styles={{ marginRight: "10px" }}
                      toggle
                      labelPosition="start"
                      disabled={isDemo === true}
                      checked={groupReportingAccess}
                      onClick={(event, isChecked) => {
                        handleGroupReportingAccess(isChecked);
                      }}
                    />
                    <Popup
                      trigger={<InfoIcon outline size="large" />}
                      content={<>
                        <p>Allows user to access dashboards.</p>
                      </>}
                      pointing
                      trapFocus
                      position={"after"}
                      align={"center"}
                      on="hover"
                    />
                  </Box>
                  <Box styles={{ width: "auto" }} className={!context.mediaCategory.sm ? "d-flex text-nowrap align-items-center" : ""}>
                    <Text weight="bold" styles={{ display: "inline-block", marginRight: "10px" }}>Subscriptions : </Text>
                    <Dropdown
                      styles={{ display: "inline-block", width: "auto", minWidth: "116px", marginRight: "10px" }}
                      fluid
                      items={groupProductTypes}
                      value={groupSubscription}
                      disabled={isDemo === true}
                      getA11ySelectionMessage={handleGroupSubscription}
                      itemToValue={(obj) => {
                        return obj.key;
                      }}
                      renderItem={(Component, props) => {
                        if (props.disabled) {
                          props.className = props.className + " _licenseUnavailable";
                        }
                        return <Component {...props}></Component>;
                      }}
                    />
                    <Popup
                      trigger={<InfoIcon outline size="large" />}
                      content={<>
                          <p>Allows {process.env.REACT_APP_APP_BRAND_NAME} to capture data for this user <br />based on product subscription type.</p>
                      </>}
                      pointing
                      trapFocus
                      position={"after"}
                      align={"center"}
                      on="hover"
                    />
                  </Box>
                </Flex>
                {/* <Flex
                  space="between"
                  styles={{ marginBottom: "10px", fontSize: "0.9rem" }}
                >
                </Flex> */}
              </>
            )}
            <Divider />
            <Flex
              gap="gap.small"
              padding="padding.medium"
              column
              styles={{ width: "100%" }}
            >
              <Flex.Item>
                <Grid
                  style={{
                    maxHeight: context.mediaCategory.sm
                      ? "calc(100vh - 320px)"
                      : "calc(100vh - 220px)",
                    minHeight: "325px"
                  }}
                  detail={context.mediaCategory.sm ? CustomListDetailedComponent : null}
                  expandField="expanded"
                  onExpandChange={expandChange}
                  data={orderBy(userData, sort)}
                  sortable={true}
                  sort={sort}
                  onSortChange={sortChange}
                >
                  <GridNoRecords>
                    {userData != null ? "No records available" : <Loader />}
                  </GridNoRecords>
                  <Column
                    width={context.mediaCategory.sm ? "40px" : "56px"}
                    title=""
                    cell={CustomCellWithUserImage}
                  />
                  <Column
                    width={context.mediaCategory.sm ? "calc(100% - 60px)" : "260px"}
                    title="Name"
                    field="userName"
                    cell={CellWithUserName}
                  />
                  {!context.mediaCategory.sm && (
                    <Column
                      width="180px"
                      title="Administration Access"
                      field="administrativeAccess"
                      cell={CustomCellWithAdministrativeAccess}
                      headerCell={HeaderCell}
                    />
                  )}
                  {!context.mediaCategory.sm && (
                    <Column
                      width="130px"
                      title="Reporting Access"
                      field="reportingAccess"
                      cell={CustomCellWithReportingAccess}
                      headerCell={HeaderCell}
                    />
                  )}
                  {!context.mediaCategory.sm && (
                    <Column
                      title="Subscription"
                      field="subscriptionId"
                      cell={CustomCellWithSubscription}
                      headerCell={HeaderCell}
                    />
                  )}
                </Grid>
              </Flex.Item>
            </Flex>
          </Box>
        )
      }
    </WindowContext.Consumer>
  );
};




const CellWithMemberList = (props) => {
  const group = props.dataItem;
  return (
    <td>
      <Button
        className="memberButton"
        onClick={() => props.onOpen(group)}
        icon={<TeamCreateIcon outline />}
        // content={"" + group.memberCount}
        size="small"
      />
    </td>
  );
};

const DetailComponent = (props) => {
  const group = props.dataItem;
  return (
    <section style={{ paddingRight: "32px" }}>
      <table>
        <tr>
          <td>Type:</td>
          <td className="text-right">
            <Text content={group.groupTypeName} />
          </td>
        </tr>
        <tr>
          <td>Privacy:</td>
          <td className="text-right">
            <Text content={group.visibilityName} />
          </td>
        </tr>
      </table>
    </section>
  );
};

const GroupsConfig = (props) => {
  const [open, setOpen] = useState(false);
  const [sort, setSort] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [groupsData, setGroupsData] = useState(null);
  const [tenantDetails, setTenantDetails] = useState(null);
  const [loggedInUserEmail, setLoggedInUserEmail] = useState(null);
  const [selectedGroupDetail, setSelectedGroupDetail] = useState(null);
  const [selectedGpUserCount, setSelectedGpUserCount] = useState(null);

  useEffect(() => {
    setSearchInput("");
    setTenantDetails(props.tenantDetails);
      setLoggedInUserEmail(props.loggedInUserEmail);
      props.viewStatus.setPageStatus(null);
      props.viewStatus.InformChildPageStatus(null);
    loadGroupsData();
  }, []);

  const onOpen = (group) => {
    setSelectedGroupDetail(group);
    setOpen(true);
  };

  const onCancel = () => {
    setOpen(false);
    setSelectedGpUserCount(null);
    setSelectedGroupDetail(null);
  };

  const expandChange = (event) => {
    let newData = groupsData.map((item) => {
      if (item.groupName === event.dataItem.groupName) {
        item.expanded = !event.dataItem.expanded;
      }
      return item;
    });
    setGroupsData(newData);
  };

  const loadGroupsData = () => {
    setGroupsData(null);
    AuthHelper.getAccessToken(function (token) {
      ConfigurationApiService.GetGroups(token).then((response) => {
        if (response  && response.status == 200) {
          var tempGroups = [];
          response.data.forEach((element) => {
            var ob = {
              groupId: element.groupId,
              groupGUID: element.groupGUID,
              groupName: element.groupName,
              email: element.email,
              groupTypeId: element.groupTypeId,
              groupTypeName: element.groupTypeName,
              memberCount: element.memberCount,
              objectTypeID: element.objectTypeID,
              reportingAccess: element.reportingAccess,
              subscriptionId: element.subscriptionId,
              visibilityId: element.visibilityId,
              visibilityName: element.visibilityName,
              administrativeAccess: element.administrativeAccess
            };
            if (element.objectTypeID !== 2)
              tempGroups.push(ob);
          });
          setGroupsData(tempGroups);
        } else {
          setGroupsData([]);
          }
          props.viewStatus.setPageStatus(response.status);
      });
    });
  };

  const handleGroupPermissions = (administrativeAccess, reportingAccess, subscriptionId) => {
    let gpData = [...groupsData];
    var indexId = gpData.findIndex((x) => x.groupId === selectedGroupDetail.groupId);
    gpData[indexId].administrativeAccess = administrativeAccess;
    gpData[indexId].reportingAccess = reportingAccess;
    gpData[indexId].subscriptionId = subscriptionId;
    setGroupsData(gpData)
  }

  const CustomCellWithMemberList = (props) => (
    <CellWithMemberList
      {...props}
      onOpen={(groupId) => {
        onOpen(groupId);
      }}
    />
  );

  const [isDemo, setIsDemo] = useState(false);
  const [isDemoMode, setIsDemoMode] = useState(global.localStorage.getItem("demoGlobal"));

  useEffect(() => {
    if (isDemoMode === "true") {
      setIsDemo(true);
    } else {
      setIsDemo(false);
    }
  }, []);

  return (
    <WindowContext.Consumer>
      {
              (context) => (props.viewStatus.pageStatus == 401 ? <Error403 /> :
                  props.viewStatus.pageStatus == 500 ? <Error500 /> : 
          <div>
            <Flex className='mx-2 mb-2 mt-0' gap='gap.large' fill>
                <h5 className='m-0'>Groups</h5>
            </Flex>
            <Flex gap="gap.small" padding="padding.medium" column fill>
              <Flex.Item>
                <Input
                  fluid
                  icon={<SearchIcon />}
                  clearable
                  value={searchInput}
                  placeholder="Search..."
                  onChange={(e, { name, value }) => {
                    setSearchInput(value);
                  }}
                />
              </Flex.Item>
              <Flex.Item>
                <Grid
                  style={{ maxHeight: "calc(100vh - 115px)" }}
                  detail={context.mediaCategory.sm ? DetailComponent : null}
                  expandField="expanded"
                  onExpandChange={expandChange}
                  data={
                    groupsData != null
                      ? orderBy(groupsData.filter((group) =>
                        group.groupName
                          .toLowerCase()
                          .includes(searchInput.toLowerCase())
                      ), sort)
                      : null
                  }
                  sortable={true}
                  sort={sort}
                  onSortChange={(e) => {
                    setSort(e.sort);
                  }}
                >
                  <GridNoRecords>
                    {groupsData != null ? "No records available" : <Loader />}
                  </GridNoRecords>
                  <Column
                    width={context.mediaCategory.sm ? "calc(100% - 150px)" : "calc(100% - 450px)"}
                    title="Group Name"
                    field="groupName"
                  />
                  {!context.mediaCategory.sm && (
                    <Column
                      width="170px"
                      title="Type"
                      field="groupTypeName"
                    //  headerCell={CenteredHeader}
                    />
                  )}
                  <Column
                    width={context.mediaCategory.sm ? "100px" : "150px"}
                    title="Members"
                    cell={CustomCellWithMemberList}
                  />
                  {!context.mediaCategory.sm && (
                    <Column
                      width="150px"
                      title="Privacy"
                      field="visibilityName"
                    />
                  )}
                </Grid>
              </Flex.Item>
            </Flex>
            {open && (
              <Dialog
                className="settingsDialog"
                title={
                  <>
                    <Box>
                      <Flex className="align-items-center">
                        <Text size="large">{selectedGroupDetail.groupName}  </Text>
                        {selectedGpUserCount !== null &&
                          <Text size="medium"> ( Members : {selectedGpUserCount} )</Text>
                        }
                        {isDemo && <div className="col-auto text-warning-demo">Exploring the app : Edits not available.</div>}
                      </Flex>
                    </Box>
                  </>
                }
                onClose={() => onCancel()}
              >
                <PeopleList
                                      selectedGroupDetail={selectedGroupDetail}
                                      loggedInUserEmail={loggedInUserEmail}
                                      isLoggedInUserAdmin={props.isLoggedInUserAdmin}
                                      isLoggedInUserTeamOwner={props.isLoggedInUserTeamOwner}
                                      tenantDetails={tenantDetails}
                                      handleGroupPermissions={handleGroupPermissions}
                                      handleMemberCount={(count) => { setSelectedGpUserCount(count); }}
                                      viewStatus={props.viewStatus }
                />
              </Dialog>
            )}
          </div>
        )
      }
    </WindowContext.Consumer>
  );
};

export default GroupsConfig;
