import React, { useState, useEffect } from 'react';
import './CalenderTimeline.scss';
import { formatMinutesToString } from './myInsights';

export const CalenderTimelineLegend = (props) => {
    return (
        <div className="timeline-legend">
            <div className={`timeline-legend-item ${props.timelineActivityVisibility.call ? '' : 'unselected'}`} onClick={() => props.toggleTimelineActivityVisibility('call') } >
                <span className="color-indicator call"></span>
                <span className="label">Calls:</span>
                <span className="value">{formatMinutesToString(props.callTime)}</span>
            </div>
            <div className={`timeline-legend-item ${props.timelineActivityVisibility.meeting ? '' : 'unselected'}`} onClick={() => props.toggleTimelineActivityVisibility('meeting')}>
                <span className="color-indicator meeting"></span>
                <span className="label">Meetings:</span>
                <span className="value">{formatMinutesToString(props.meetingTime)}</span>
            </div>
            <div className={`timeline-legend-item ${props.timelineActivityVisibility.overrunmeeting ? '' : 'unselected'}`} onClick={() => props.toggleTimelineActivityVisibility('overrunmeeting')}>
                <span className="color-indicator meeting-overrun"></span>
                <span className="label">Overrun Meetings:</span>
                <span className="value">{formatMinutesToString(props.meetingoverrunTime)}</span>
            </div>
        </div>
    );
};

const CalenderActivityBlock = (props) => {
    // Calculate the position and width based on duration
    const startMinutes = props.activity.time % 60;
    const durationMinutes = props.activity.duration;
    const widthPercent = props.activity.type === 'message' ? 0 : (durationMinutes / 60) * 100;
    const size = props.activity.type === 'message' ? Math.cbrt(props.activity.count) * 5 : 0; // Adjust scaling factor for size
    return (
        (() => {
            switch (props.activity.type) {
                case 'call':
                    return (
                        <div
                            title={props.activity.title}
                            key={props.itemKey}
                            className={`activity-block ${props.activity.type} ${props.timelineActivityVisibility.call ? '' : 'd-none'}`}
                            style={{
                                left: `${(startMinutes / 60) * 100}%`,
                                width: `${widthPercent}%`,
                            }}
                        ></div>
                    );
                case 'meeting':
                    return (
                        <div
                            title={props.activity.title}
                            key={props.itemKey}
                            className={`activity-block ${props.activity.type} ${(props.timelineActivityVisibility.meeting && props.activity.overrunDuration === 0) || (props.timelineActivityVisibility.overrunmeeting && props.activity.overrunDuration > 0) ? '' : 'd-none'}`}
                            style={{
                                left: `${(startMinutes / 60) * 100}%`,
                                width: `${widthPercent}%`,
                            }}
                        >
                            <div className={`normal-duration ${props.activity.overrunDuration === 0 ? 'b-smooth' : ''}`} style={{ width: `${((props.activity.duration - props.activity.overrunDuration) / props.activity.duration) * 100}%` }}></div>
                            <div className="overrun-duration" style={{ width: `${((props.activity.overrunDuration) / props.activity.duration) * 100}%` }}></div>
                        </div>
                    );
                case 'message':
                    return (
                        <div
                            title={props.activity.title}
                            key={props.itemKey}
                            className={`activity-block-bubble ${props.activity.type} ${props.timelineActivityVisibility.message ? '' : 'd-none'}`}
                            style={{
                                left: `${(startMinutes / 60) * 100}%`,
                                width: `${size}px`,
                                height: `${size}px`
                            }}
                        ></div>
                    );
                default:
                    return null;
            }
        })()
    );
}

export const CalenderTimeline = (props) => {
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [dates, setDates] = useState(null);
    const [times, setTimes] = useState(null);
    const [loading, setLoading] = useState(true);
    const [activityData, setActivityData] = useState(null);
    
    // Initialize startDate and endDate in useEffect
    useEffect(() => {
        setStartDate(props.startDate);
        setEndDate(props.endDate);
        let dateArray = generateDateRange(props.startDate, props.endDate);
        setDates(dateArray);
        let timeArray = generateTimeRange(props.startTime, props.endTime);
        setTimes(timeArray);
        let formattedData = formatActivityData(parseInt(props.startTime.split(':')[0], 10), dateArray, timeArray, props.activities);
        setActivityData(formattedData);
        setLoading(false); // Set loading to false once dates are initialized
        console.log('calender')
        console.log(props.activities)
    }, [props]);

    function formatDateToISO(date, dateOnly) {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const day = String(date.getDate()).padStart(2, '0');
        const hours = dateOnly ? '00' : String(date.getHours()).padStart(2, '0');
        const minutes = dateOnly ? '00' : String(date.getMinutes()).padStart(2, '0');
        const seconds = dateOnly ? '00' : String(date.getSeconds()).padStart(2, '0');

        return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
    }

    // Generate dates between startDate and endDate
    const generateDateRange = (start, end) => {
        const dateArray = [];
        let currentDate = new Date(start);
        while (currentDate <= end) {
            dateArray.push(formatDateToISO(new Date(currentDate), true));
            currentDate.setDate(currentDate.getDate() + 1);
        }
        return dateArray;
    };

    const generateTimeRange = (startTime, endTime) => {
       
        const times = [];

        // Helper function to parse the hour from HH:mm
        const parseHour = (time) => parseInt(time.split(':')[0], 10);

        const startHour = parseHour(startTime);
        const endHour = parseHour(endTime);

        if (startHour > endHour) {
            throw new Error("Invalid time range. Ensure startTime is earlier than endTime.");
        }

        // Loop through hours only
        for (let hour = startHour; hour <= endHour; hour++) {
            const formattedHour = `${hour % 12 || 12} ${hour < 12 ? 'AM' : 'PM'}`;
            times.push(formattedHour);
        }

        return times;
    };


    // Function to get time in minutes from 'HH:MM' format
    function timeToMinutes(time) {
        const [hours, minutes] = time.split(':').map(Number);
        return hours * 60 + minutes;
    }

    const formatActivityData = (offsetHour, dateArray, _timeArray, activityData) => {
        
        let dateIndexLookup = dateArray.reduce((acc, date, index) => {
            acc[date] = index;
            return acc;
        }, {});
        let timeArray = Array.from({ length: _timeArray.length }, (_, i) => (i + offsetHour) * 60);

        let formattedObj = activityData.reduce((acc, activity) => {
            // Find dateIndex using pre-computed lookup
            const dateIndex = dateIndexLookup[activity.date];

            // Find timeIndex based on timeInMinutes (Binary Search)
            const timeInMinutes = activity.time; // timeToMinutes(activity.time);
            const timeIndex = timeArray.findIndex((t, i) => timeInMinutes >= t && timeInMinutes < (timeArray[i + 1] || Infinity));

            // Add dateIndex and timeIndex to the activity object
            const activityWithIndex = { ...activity, dateIndex, timeIndex };

            // Group activities by dateIndex and timeIndex
            if (!acc[dateIndex]) {
                acc[dateIndex] = {}; // Initialize dateIndex group
            }
            if (!acc[dateIndex][timeIndex]) {
                acc[dateIndex][timeIndex] = []; // Initialize timeIndex group
            }
            acc[dateIndex][timeIndex].push(activityWithIndex);

            return acc;
        }, {})
        return formattedObj;
    }
   
    

    return (
        loading ? <div>Loading timeline...</div> :
                    <div className="timeline-row-container">
                        {/* Time headers */}
                        <div className="timeline-row" style={{ gridTemplateColumns: `120px repeat(${times.length}, minmax(100px, 1fr))` }}>
                            <div className="corner-cell sticky-cell"><span>{`${startDate.toLocaleDateString("en-US", {
                                month: "short",
                                day: "numeric",
                                year: "numeric",
                            })} - ${endDate.toLocaleDateString("en-US", {
                                month: "short",
                                day: "numeric",
                                year: "numeric",
                            })}`}</span></div>
                            {times.map((time, index) => (
                                <div key={index} className="header-cell time-cell">
                                    {time}
                                </div>
                            ))}
                        </div>
                        {/* Date rows */}
                        {dates.map((date, dateIndex) => (
                            <div key={dateIndex} className="timeline-row" style={{ gridTemplateColumns: `120px repeat(${times.length}, minmax(100px, 1fr))` }}>
                                <div className="header-cell date-cell sticky-cell">
                                    {new Date(date).toLocaleDateString("en-US", { month: "short", day: "numeric" })}
                                </div>
                                {times.map((_, timeIndex) => (
                                    <div key={timeIndex} className="time-slot">
                                        {
                                            activityData[dateIndex]?.[timeIndex]?.map((activity, activityIndex) => (
                                                <CalenderActivityBlock timelineActivityVisibility={props.timelineActivityVisibility}  activity={activity} itemKey={`${dateIndex}_${timeIndex}_${activityIndex}`} />
                                            ))
                                        }
                                    </div>
                                ))}
                            </div>
                        ))}
        </div>
    );
};

