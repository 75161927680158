import React, { useEffect, useState } from 'react'
import './insightstyle.scss';
import { Avatar, Popup, Tooltip, PersonIcon, UserFriendsIcon, ArrowUpIcon, ChevronDownIcon, Button } from '@fluentui/react-northstar';
import WindowContext from '../../Shared/Context/Context';
import { CalenderTimeline, CalenderTimelineLegend } from './CalenderTimeline';
import { teamData, manager, userData, generationRandomTimelineData } from './DemoData'


export function formatMinutesToString(totalMinutes) {
    const hours = Math.floor(totalMinutes / 60); // Get the total hours
    const minutes = Math.floor(totalMinutes % 60); // Remaining minutes after hours
    const seconds = Math.round((totalMinutes - Math.floor(totalMinutes)) * 60); // Convert fractional minutes to seconds

    // Build the string dynamically
    let timeString = "";
    if (hours > 0) {
        timeString += `${hours}h `;
    }
    if (minutes > 0 || hours > 0) { // Include minutes if hours are present
        timeString += `${minutes}m `;
    }
    timeString += `${seconds}s`;

    return timeString.trim(); // Remove trailing space
}

function fnGetZoneIndicator(percentageValue, zones, expectedPercentage) {
    let evalZoneVal = {
        zoneCol: "",
        zonInd: "",
        zoneDes1: "",
        zoneDes2: "",

    }
    if ((percentageValue == null)) {
        evalZoneVal = {
            zoneCol: "text-disable1",
            zonInd: "bg-disable1",
            zoneDes1: `${process.env.REACT_APP_TEAMVIBE_MSG4}`,
            zoneDes2: "",

        }
    }
    else if (expectedPercentage !== null && zones != null) {
        let difference = Math.abs(Number(percentageValue - expectedPercentage));
        if (percentageValue >= 0 && percentageValue < zones[0]) {
            evalZoneVal = {
                zoneCol: "text-red1",
                zonInd: "bg-red1",
                zoneDes1: `${process.env.REACT_APP_TEAMVIBE_MSG1}`,
                zoneDes2: Math.floor((difference > expectedPercentage ? expectedPercentage : difference * 100) / expectedPercentage * 100) / 100 + `${process.env.REACT_APP_TEAMVIBE_MSG3}`,

            }
        }
        else if (percentageValue > zones[0] && percentageValue < zones[1]) {
            evalZoneVal = {
                zoneCol: "text-amber1",
                zonInd: "bg-amber1",
                zoneDes1: `${process.env.REACT_APP_TEAMVIBE_MSG1}`,
                zoneDes2: Math.floor((difference > expectedPercentage ? expectedPercentage : difference * 100) / expectedPercentage * 100) / 100 + `${process.env.REACT_APP_TEAMVIBE_MSG3}`,

            }
        }
        else if (percentageValue > zones[1] && percentageValue < zones[2]) {
            evalZoneVal = {
                zoneCol: "text-green1",
                zonInd: "",
                zoneDes1: "",
                zoneDes2: `${process.env.REACT_APP_TEAMVIBE_MSG5}`,

            }
        }
        else if (percentageValue > zones[2] && percentageValue < zones[3]) {
            evalZoneVal = {
                zoneCol: "text-amber1",
                zonInd: "bg-amber1",
                zoneDes1: `${process.env.REACT_APP_TEAMVIBE_MSG1}`,
                zoneDes2: Math.floor((difference > expectedPercentage ? expectedPercentage : difference * 100) / expectedPercentage * 100) / 100 + `${process.env.REACT_APP_TEAMVIBE_MSG2}`,

            }
        }
        else if (percentageValue > zones[3] && percentageValue <= 100) {
            evalZoneVal = {
                zoneCol: "text-red1",
                zonInd: "bg-red1",
                zoneDes1: `${process.env.REACT_APP_TEAMVIBE_MSG1}`,
                zoneDes2: Math.floor(((difference > expectedPercentage ? expectedPercentage : difference) * 100) / expectedPercentage * 100) / 100 + `${process.env.REACT_APP_TEAMVIBE_MSG2}`,

            }

        }
    }


    return evalZoneVal;
}
function fnGetTimeRange(timeCategory) {
    let calender = {
        startTime: "09:30",
        endTime: "18:30"
    }
    switch (timeCategory) {
        case 1:
            calender = {
                startTime: "00:00",
                endTime: "23:59"
            }
            break;
        case 2:
            calender = {
                startTime: "09:30",
                endTime: "18:30"
            }
            break;
        default:
            calender = {
                startTime: "18:30",
                endTime: "23:59"
            }

    }
    return calender;
}
const SentimentsCards = (props) => {

    return (
        <>
            {props.userList.length > 0 && props.userList.map((user) => (
                <div className="sentiments-list-card">
                    <div className="avatar-wrapper">
                        <div className=".name-label">
                            <Avatar
                                className='bg-avatar2 nv-1'
                                name={user.value.displayName}
                                icon={<PersonIcon />}
                                size="small"
                            />
                        </div>
                        <div className=''>
                            <div className='list-lable'>{user.value.displayName}</div>
                            <div className='list-position-lable'>{user.value.designation}</div>
                        </div>
                    </div>
                    <div className="sentiments-kpi-wrapper">
                        <div>{user.value.callDuration}</div>
                        <div>Chats : {user.value.overallMessageCount}</div>
                    </div>
                </div>
            ))}
        </>
    )
}

const UserView = (props) => {
    const [timelineActivityVisibility, setTimelineActivityVisibility] = useState({ call: true, meeting: true, message: true, overrunmeeting: true });
    const [activities, setActivities] = useState([]);
    const [timeRange, setTimeRange] = useState(fnGetTimeRange(props.filterData.timeCategory));

    useEffect(() => {
        if (props.isDemo) {
            let demoActivities = generationRandomTimelineData(props.filterData.startDate, props.filterData.endDate);
            setActivities(demoActivities);
        }
        else {
            setActivities(props.userData[0].userCalenderDetails.activities);
        }
    }, [props.filterData])

    return (
        <div className='user-view-container'>
            <div className="card-user-view mb-4">
                <div className="c-m-body mt-1">
                    <CalenderTimeline
                        timelineActivityVisibility={timelineActivityVisibility}
                        activities={activities}
                        startDate={new Date(props.filterData.startDate)}
                        endDate={new Date(props.filterData.endDate)}
                        startTime={timeRange.startTime}
                        endTime={timeRange.endTime}
                    />
                </div>
            </div>
            <div className="card-user-view shadow-none bg-accent1">
                <div className="c-m-body">
                    <CalenderTimelineLegend
                        timelineActivityVisibility={timelineActivityVisibility}
                        toggleTimelineActivityVisibility={(activityType) => setTimelineActivityVisibility({ ...timelineActivityVisibility, [activityType]: !timelineActivityVisibility[activityType] })}
                        callTime={activities.filter(item => item.type === 'call').reduce((sum, item) => sum + item.duration, 0)}
                        meetingTime={activities.filter(item => item.type === 'meeting').reduce((sum, item) => sum + item.duration, 0)}
                        meetingoverrunTime={activities.filter(item => item.type === 'meeting').reduce((sum, item) => sum + item.overrunDuration, 0)} />
                </div>
            </div>
            <div className="row mx-0 align-items-center" style={{ height: "208px", marginTop: '36px', gap: '36px' }}>
                <div className="card-user-view shadow-none col-12 col-lg-4 h-100">
                    <div className="c-m-head">Calls & Meetings</div>
                    <div className="c-m-body">
                        <div className="sentiments-wrap-container">
                            <div className="user-sentiments-container bg-bbc-t">
                                <div className="calls-meetings-insight">
                                    <div className="">Meetings taking longer</div>
                                    <div className="qty-nmbr rounded-pill">5</div>
                                    <div className="qty-time">1h 34m 23s</div>
                                </div>
                                <div className="calls-meetings-insight">
                                    <div className="">Time in overrun meetings</div>
                                    <div className="qty-nmbr rounded-pill">54</div>
                                    <div className="qty-time">1h</div>
                                </div>
                                <div className="calls-meetings-insight">
                                    <div className="">Less attended Meetings</div>
                                    <div className="qty-nmbr rounded-pill">4</div>
                                    <div className="qty-time">1h 34m 23s</div>
                                </div>
                                <div className="calls-meetings-insight">
                                    <div className="">Less attended Meetings</div>
                                    <div className="qty-nmbr rounded-pill">0</div>
                                    <div className="qty-time">23s</div>
                                </div>
                                <div className="calls-meetings-insight">
                                    <div className="">Less attended Meetings</div>
                                    <div className="qty-nmbr rounded-pill">12</div>
                                    <div className="qty-time">3m 23s</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-user-view shadow-none col h-100">
                    <div className="c-m-head">Sentiments with people</div>
                    <div className="c-m-body">
                        <div className="sentiments-wrap-container">
                            <div className="user-sentiments-container bg-neg-t">
                                <div className="sentiment-type neg-t">
                                    Negative
                                </div>
                                {props.userData[0].negativeSentimentUsersList.length === 0 ? <div className="container-empty-message">No one yet!</div> :
                                    <SentimentsCards userList={props.userData[0].negativeSentimentUsersList} />}
                            </div>
                            <div className="user-sentiments-container bg-pos-t">
                                <div className="sentiment-type pos-t">
                                    Positive
                                </div>
                                {props.userData[0].positiveSentimentUsersList.length === 0 ? <div className="container-empty-message">No one yet!</div>
                                    : <SentimentsCards userList={props.userData[0].positiveSentimentUsersList} />}
                            </div>
                            <div className="user-sentiments-container bg-neu-t">
                                <div className="sentiment-type neu-t">
                                    Neutral
                                </div>
                                {props.userData[0].neutralSentimentUsersList.length === 0 ? <div className="container-empty-message">No one yet!</div>
                                    : <SentimentsCards userList={props.userData[0].neutralSentimentUsersList} />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const MyTeamCard = (props) => {
    const data = props.isDemo ? teamData : props.insightData.teamData;
    return (
        <div className="row mx-0 my-team-container">
            <div className="col">
                <div className="row">
                    <div className="col team-progress">
                        <div className="team-progress-body">
                            <div className="progress-contains">
                                <div className="prog-lable-container">
                                    <div className="prog-title">Calls and Meetings</div>
                                    <div className="prog-val-total">
                                        <span className="name-lable">{data.teamCallsPercentage}</span>
                                        <span className="symbol">%</span>
                                    </div>
                                </div>
                                <div className="prog-bar-container">
                                    {data.teamCallsPercentage === 0 && <div className="prog-bars no-data-pb" style={{ width: "0%" }}></div>}
                                    {data.teamCallsPercentage !== 0 && <div className="prog-bars calls-meetings" style={{ width: `${data.teamCallsInternalPercentage}%` }}></div>}
                                    {data.teamCallsPercentage !== 0 && <div className="prog-bars calls-meetings" style={{ width: `${data.teamCallsExternalPercentage}%` }}></div>}

                                </div>
                                <div className="bar-lables-container">
                                    <div className="bar-ind calls-meetings-internal">
                                    </div>
                                    <div className="bar-lable-name">Internal</div>
                                    <div className="bar-lable-name">{data.teamCallsInternal}</div>
                                </div>
                                <div className="bar-lables-container">
                                    <div className="bar-ind calls-meetings-external">
                                    </div>
                                    <div className="bar-lable-name">External</div>
                                    <div className="bar-lable-name">{data.teamCallsExternal}</div>
                                </div>
                            </div>

                            <div className="progress-contains">
                                <div className="prog-lable-container">
                                    <div className="prog-title">Chats and Messages</div>
                                    <div className="prog-val-total">
                                        <span className="name-lable">{data.teamMsgPercentage}</span>
                                        <span className="symbol">%</span>
                                    </div>
                                </div>
                                <div className="prog-bar-container">
                                    {data.teamMsgPercentage === 0 && <div className="prog-bars no-data-pb" style={{ width: "0%" }}></div>}
                                    { data.teamMsgPercentage !== 0 && <div className="prog-bars chats-messages" style={{ width: `${data.teamMsgInternalPercentage}%` }}></div>}
                                    { data.teamMsgPercentage !== 0 && <div className="prog-bars chats-messages" style={{ width: `${data.teamMsgExternalPercentage}%` }}></div>}
                                </div>
                                <div className="bar-lables-container">
                                    <div className="bar-ind chats-messages-internal">
                                    </div>
                                    <div className="bar-lable-name">Internal</div>
                                    <div className="bar-lable-name">{data.teamMsgInternal}</div>
                              </div>
                                <div className="bar-lables-container">
                                    <div className="bar-ind chats-messages-external">
                                    </div>
                                    <div className="bar-lable-name">External</div>
                                 <div className="bar-lable-name">{data.teamMsgExternal}</div>
                                </div>
                            </div>

                            <div className="progress-contains">
                                <div className="prog-lable-container">
                                    <div className="prog-title">Average Focus Time</div>
                                    <div className="prog-val-total">
                                        <span className="name-lable">{100 - (data.teamMsgPercentage + data.teamCallsPercentage)}</span>
                                       <span className="symbol">%</span>
                                    </div>
                                </div>
                                <div className="prog-bar-container">
                                    <div className="prog-bars avg-focus" style={{
                                        width: `${100 - (data.teamMsgPercentage + data.teamCallsPercentage)}%`
                                    }}></div>
                                    <div className="prog-bars avg-focus" style={{
                                        width:  `${(data.teamMsgPercentage + data.teamCallsPercentage)}%`}}></div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div className="col min-width300">
                <div className="row">
                    <div className="col team-vibe">
                        <div className="team-vibe-head">Team Vibe</div>
                        <div className="team-vibe-body">
                            <div className="text-center">
                                <svg role="progressbar" width="84px" height="104px" viewBox='0 0 auto auto' fill="none" aria-valuemin="0" aria-valuemax="100" aria-valuenow="5">
                                    <circle cx="50%" cy="50%" r="36" shape-rendering="geometricPrecision" className="pie-prog-base" fill="none" stroke-width="1"
                                        stroke-dashoffset={26} stroke-dasharray="226.3" stroke-linecap="round" stroke="#BDBDBD" style={{
                                            transform: 'rotate(112deg)',
                                            transformOrigin: '50% 50%',
                                        }}>
                                    </circle>
                                    <circle cx="50%" cy="50%" r="36" shape-rendering="geometricPrecision" className="pie-prog-indicator" fill="none" stroke-width="4"
                                        stroke-dashoffset={26 + ((200.3 / 100) * (100 - Number(data.teamVibe)))} stroke-dasharray="226.3" stroke-linecap="round"
                                        stroke={data.teamVibe > 60 ? "#6FCF97" : data.teamVibe > 30 ? "#FF9500" : "#EB5757"}
                                        style={{
                                            transform: 'rotate(112deg)',
                                            transformOrigin: '50% 50%',
                                        }}>
                                    </circle>
                                    <text className="prog-text" x="52%" y="58%" fill="#000" text-anchor="middle" dy="" font-weight="400">
                                        <tspan className="pie-prog-lable">{Number(data.teamVibe).toFixed(1)}</tspan>
                                        <tspan className="pie-lable-symbol">%</tspan>
                                    </text>
                                    <text className="prog-text" x="50%" y="9%" fill="#000" text-anchor="middle" dy="" >
                                        <tspan className="prog-lable">Neutral</tspan>
                                    </text>
                                    <text className="prog-text" x="25%" y="94%" fill="#000" text-anchor="middle" dy="">
                                        <tspan className="prog-lable">Negative</tspan>
                                    </text>
                                    <text className="prog-text" x="75%" y="94%" fill="#000" text-anchor="middle" dy="">
                                        <tspan className="prog-lable">Positive</tspan>
                                    </text>
                                </svg>
                                <div className='vibe-lable'>
                                    {data.teamVibe > 60 ? "Good" : data.teamVibe > 30 ? "Average" : "Poor"}

                                </div>
                                <div className='vibe-trends d-inline-flex'>
                                    <svg width="13" height="12" viewBox="0 0 13 12" className= {data.vibeDiff >= 0 ? 'trend arrow_up' : 'trend arrow_down'} xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.7502 10.5C7.16441 10.5 7.5002 10.1642 7.5002 9.75V3.81066L9.46987 5.78033C9.76276 6.07322 10.2376 6.07322 10.5305 5.78033C10.8234 5.48744 10.8234 5.01256 10.5305 4.71967L7.28053 1.46967C6.98764 1.17678 6.51278 1.17678 6.21988 1.46965L2.96969 4.71965C2.67678 5.01254 2.67677 5.48741 2.96965 5.78031C3.26254 6.07322 3.73741 6.07323 4.03031 5.78035L6.0002 3.81058V9.75C6.0002 10.1642 6.33598 10.5 6.7502 10.5Z" />
                                    </svg>
                                    {data.vibeDiff >= 0 ? <div className='trend-lable'>
                                        {data.vibeDiff}% up in last 30 days
                                    </div> :
                                        <div className='trend-lable'>
                                            {data.vibeDiff}% down in last 30 days
                                        </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col team-sentiments">
                        <div className="team-sentiments-head mb-1">Team Sentiments</div>
                        <div className="team-sentiments-body">
                            <div className="body-wraps">
                                <div className="sentiments-bg img-internal">
                                    <div className="sentiments-ind-lable">
                                        <span className='ind-lable'>
                                            {Number(data.teamSentimentVal).toFixed(1)}
                                        </span>
                                        <span className='ind-symbol'>
                                            %
                                        </span>
                                    </div>
                                </div>
                                <div className="row mt-1 int-ext-lable">
                                    <div className="col text-center internal-lable selection">
                                        Internal
                                    </div>
                                    <div className="col text-center internal-lable ">
                                        External
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


const Card = ({ userView, sidePan, wellBeingScoreDescriptionList, fnUpdateUserToView, teamView, isDemo, ...data}) => {
    if (!teamView) {
        data = data[0];
    }

    const [toggleCard, setToggleCard] = useState(false)

    const calls = fnGetZoneIndicator(data.callsPercentage, data.callsZone, data.expectedCallsPercentage);
    const meetings = fnGetZoneIndicator(data.meetingsPercentage, data.meetingsZone, data.expectedMeetingsPercentage);
    const focussed = fnGetZoneIndicator(data.focusedPercentage, data.focusedZone, data.expectedFocusedPercentage);

    return (
        <div className={`card-container ${sidePan ? 'side-pan' : ''}`}>
            {
                userView && <div className="profile-header align-items-center mb-0 hover-none">
                <div className='.name-label'>
                    <Avatar
                        className='bg-avatar2'
                        name={data.userName}
                        icon={<PersonIcon />}
                        size="large"
                        image={data.userImage ? data.userImage : null}
                        status={data.userStatus}
                    />

                </div>
                <div className=''>
                    <div className='name-label'>{data.userName}</div>
                    <div className='level-label'>{data.userDesignation}</div>
                    <div className='org-email'>{data.userEmail}</div>
                </div>
                {/* {sidePan && <div className='ml-auto team-vibe'>
                    <div className='vibe-label'>Team Vibe</div>
                    <div className='vibe-val vibe-neu'>Neutral</div>
                </div>} */}
                <Button
                    className='ml-auto'
                    onClick={() => setToggleCard(!toggleCard)}
                    icon={<ChevronDownIcon rotate={toggleCard ? 180 : 0}/>}
                    text
                    iconOnly
                    title="dropdown" />
            </div>
            }
            { !userView ?
                <>
                <div className="container-left">
                    {!userView && <div className={`profile-header align-items-center ${!teamView ? "hover-none" : ""} ${sidePan ? 'side-pan' : ''}`} onClick={() => fnUpdateUserToView(data.userGUID, false)}>
                        <div className='.name-label'>
                            <Avatar
                                className='bg-avatar2'
                                name={data.userName}
                                icon={<PersonIcon />}
                                size="large"
                                image={data.userImage ? data.userImage : null}
                                status={data.userStatus}
                            />

                        </div>
                        <div className=''>
                            <div className='name-label'>{data.userName}</div>
                            <div className='level-label'>{data.userDesignation}</div>
                            <div className='org-email'>{data.userEmail}</div>
                        </div>
                        {/* {sidePan && <div className='ml-auto team-vibe'>
                            <div className='vibe-label'>Team Vibe</div>
                            <div className='vibe-val vibe-neu'>Neutral</div>
                        </div>} */}
                    </div>}
                    <div className="collaboration-intelligence">
                        <div className="head">
                            Collaboration Intelligence
                        </div>
                        <div className="body">
                            <div className="timespent-container">
                                <div className="timespent-wrapper">
                                    <div className="time-spent">
                                        <div className="label-group">
                                            <div className="vl bg-calls1">
                                            </div>
                                            <div className="labels">
                                                <div className="label1">Calls & Meetings</div>
                                                <div className="label2">{meetings.zoneDes1}</div>
                                            </div>
                                        </div>
                                        <div className="progress-label">
                                            <div className="value">
                                                {data.meetingsPercentage}
                                            </div>
                                            <div className="value-type">
                                                %
                                            </div>
                                            <div className={`indicates ${meetings.zonInd}`}>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="time-spent">
                                        <div className="label-group">
                                            <div className={`vl-dot ${meetings.zonInd}`}>
                                            </div>
                                            <div className="labels">
                                                <div className={`label3 ${meetings.zoneCol}`}>{meetings.zoneDes2}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="timespent-wrapper">
                                    <div className="time-spent">
                                        <div className="label-group">
                                            <div className="vl bg-chats1">
                                            </div>
                                            <div className="labels">
                                                <div className="label1">Chats & Messages</div>
                                                <div className="label2">{calls.zoneDes1}</div>
                                            </div>
                                        </div>
                                        <div className="progress-label">
                                            <div className="value">
                                                {data.callsPercentage}
                                            </div>
                                            <div className="value-type">
                                                %
                                            </div>
                                            <div className={`indicates ${calls.zonInd}`}>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="time-spent">
                                        <div className="label-group">
                                            <div className={`vl-dot ${calls.zonInd}`}>
                                            </div>
                                            <div className="labels">
                                                <div className={`label3 ${calls.zoneCol}`}>{calls.zoneDes2}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="timespent-wrapper">
                                    <div className="time-spent">
                                        <div className="label-group">
                                            <div className="vl bg-focus1">
                                            </div>
                                            <div className="labels">
                                                <div className="label1">Available focus time</div>
                                                <div className="label2">{focussed.zoneDes1}</div>
                                            </div>
                                        </div>
                                        <div className="progress-label">
                                            <div className="value">
                                                {data.focusedPercentage}
                                            </div>
                                            <div className="value-type">
                                                %
                                            </div>
                                            <div className={`indicates ${focussed.zonInd}`}>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="time-spent">
                                        <div className="label-group">
                                            <div className={`vl-dot ${focussed.zonInd}`}>
                                            </div>
                                            <div className="labels">
                                                <div className={`label3 ${focussed.zoneCol}`}>{focussed.zoneDes2}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`container-right ${sidePan ? 'side-pan' : ''}`}>
                    <div className={`employee-vibe ${sidePan ? 'side-pan' : ''}`}>
                        <div className="c-ev-head">
                            Employee Vibe
                        </div>
                        <div className="c-ev-body">
                            <div className={`response-container ${sidePan ? 'side-pan' : ''}`}>

                                {data.categoryTag !== null ? data.categoryTag.map((item) =>
                                    <div className="category-container">
                                        <div className="cat-label" title={item.label}>{item.label}</div>
                                        <div className="value-container">
                                            <div className="val-label">{wellBeingScoreDescriptionList[item.val]}</div>
                                            <div className={`val-indicate-container val-response-${item.val}`}>
                                                <div className="val-indicate"></div>
                                                <div className="val-indicate"></div>
                                                <div className="val-indicate"></div>
                                                <div className="val-indicate"></div>
                                                <div className="val-indicate"></div>
                                            </div>
                                        </div>
                                    </div>) : <div className='mx-auto text-secondary'>Data is currently unavailable</div>
                                }

                            </div>
                        </div>
                    </div>
                    {!userView && <div className={`sentiments-people ${sidePan ? 'side-pan' : ''}`}>
                        <div className="head">
                            Sentiments with people
                        </div>
                        <div className="neg-section">
                            <div className="neg-label">
                                Negative
                            </div>
                            {data.negativeSentimentUsersList.length > 0 ? <div className={`neg-body rounded-pill ${sidePan ? 'side-pan' : ''}`}>
                                {data.negativeSentimentUsersList.length > 5 ? <>
                                    {data.negativeSentimentUsersList.slice(0, 5).map((user) => (

                                        <Tooltip
                                            content={user.value.displayName}
                                            pointing
                                            position='below'
                                            trigger={
                                                <Avatar
                                                    name={user.value.displayName}
                                                    //image={user.value.photo}
                                                    size="small"
                                                />
                                            }
                                        />

                                    ))}
                                    <Popup
                                        trigger={<Avatar className="ui-avt-shadow" label={"+" + (data.negativeSentimentUsersList.length - 5)} />}
                                        on="hover"
                                        pointing
                                        content={
                                            <div style={{ maxWidth: "250px" }}>
                                                {
                                                    data.negativeSentimentUsersList.slice(5).map(user => {
                                                        return <Tooltip
                                                            content={user.displayName}
                                                            pointing
                                                            position='below'
                                                            trigger={
                                                                <Avatar
                                                                    name={user.displayName}
                                                                    //image={user.value.photo}
                                                                    size="small"
                                                                />
                                                            }
                                                        />
                                                    })
                                                }
                                            </div>
                                        }
                                    />
                                </>
                                    :
                                    data.negativeSentimentUsersList.slice(0, 5).map((user) => (
                                        <Tooltip
                                            content={user.value.displayName}
                                            pointing
                                            position='below'
                                            trigger={
                                                <Avatar
                                                    name={user.value.displayName}
                                                    //image={user.value.photo}
                                                    size="small"
                                                />
                                            }
                                        />
                                    ))
                                }

                            </div> :
                                <div className={`neg-body rounded-pill ${sidePan ? 'side-pan' : ''}`}>
                                    <div className="mx-auto text-secondary">No one yet!</div>
                                </div>
                            }
                        </div>
                        <div className="other-section">
                            <div className={`other-container ${sidePan ? 'side-pan' : ''}`}>
                                <div className="other-label">
                                    Positive
                                </div>
                                <div className="other-value text-green1">
                                    <UserFriendsIcon outline size='large' />
                                    <div className="other-val">
                                        {data.positiveSentimentCount}
                                    </div>
                                </div>
                            </div>
                            <div className={`other-container ${sidePan ? 'side-pan' : ''}`}>
                                <div className="other-label">
                                    Neutral
                                </div>
                                <div className="other-value text-amber1">
                                    <UserFriendsIcon outline size='large' />
                                    <div className="other-val">
                                        {data.neutralSentimentCount}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>}
                </div>
                </> :
                toggleCard && <>
                <div className="container-left">
                    <div className="collaboration-intelligence">
                        <div className="head">
                            Collaboration Intelligence
                        </div>
                        <div className="body">
                            <div className="timespent-container">
                                <div className="timespent-wrapper">
                                    <div className="time-spent">
                                        <div className="label-group">
                                            <div className="vl bg-calls1">
                                            </div>
                                            <div className="labels">
                                                <div className="label1">Calls & Meetings</div>
                                                <div className="label2">{meetings.zoneDes1}</div>
                                            </div>
                                        </div>
                                        <div className="progress-label">
                                            <div className="value">
                                                {data.meetingsPercentage}
                                            </div>
                                            <div className="value-type">
                                                %
                                            </div>
                                            <div className={`indicates ${meetings.zonInd}`}>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="time-spent">
                                        <div className="label-group">
                                            <div className={`vl-dot ${meetings.zonInd}`}>
                                            </div>
                                            <div className="labels">
                                                <div className={`label3 ${meetings.zoneCol}`}>{meetings.zoneDes2}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="timespent-wrapper">
                                    <div className="time-spent">
                                        <div className="label-group">
                                            <div className="vl bg-chats1">
                                            </div>
                                            <div className="labels">
                                                <div className="label1">Chats & Messages</div>
                                                <div className="label2">{calls.zoneDes1}</div>
                                            </div>
                                        </div>
                                        <div className="progress-label">
                                            <div className="value">
                                                {data.callsPercentage}
                                            </div>
                                            <div className="value-type">
                                                %
                                            </div>
                                            <div className={`indicates ${calls.zonInd}`}>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="time-spent">
                                        <div className="label-group">
                                            <div className={`vl-dot ${calls.zonInd}`}>
                                            </div>
                                            <div className="labels">
                                                <div className={`label3 ${calls.zoneCol}`}>{calls.zoneDes2}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="timespent-wrapper">
                                    <div className="time-spent">
                                        <div className="label-group">
                                            <div className="vl bg-focus1">
                                            </div>
                                            <div className="labels">
                                                <div className="label1">Available focus time</div>
                                                <div className="label2">{focussed.zoneDes1}</div>
                                            </div>
                                        </div>
                                        <div className="progress-label">
                                            <div className="value">
                                                {data.focusedPercentage}
                                            </div>
                                            <div className="value-type">
                                                %
                                            </div>
                                            <div className={`indicates ${focussed.zonInd}`}>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="time-spent">
                                        <div className="label-group">
                                            <div className={`vl-dot ${focussed.zonInd}`}>
                                            </div>
                                            <div className="labels">
                                                <div className={`label3 ${focussed.zoneCol}`}>{focussed.zoneDes2}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`container-right`}>
                    <div className={`employee-vibe`}>
                        <div className="c-ev-head">
                            Employee Vibe
                        </div>
                        <div className="c-ev-body">
                            <div className={`response-container`}>

                                {data.categoryTag !== null ? data.categoryTag.map((item) =>
                                    <div className="category-container">
                                        <div className="cat-label" title={item.label}>{item.label}</div>
                                        <div className="value-container">
                                            <div className="val-label">{wellBeingScoreDescriptionList[item.val]}</div>
                                            <div className={`val-indicate-container val-response-${item.val}`}>
                                                <div className="val-indicate"></div>
                                                <div className="val-indicate"></div>
                                                <div className="val-indicate"></div>
                                                <div className="val-indicate"></div>
                                                <div className="val-indicate"></div>
                                            </div>
                                        </div>
                                    </div>) : <div className='mx-auto text-secondary'>Data is currently unavailable</div>
                                }

                            </div>
                        </div>
                    </div>
                </div>
                </>
            }
        </div >

    )
}

function formatDateToISO(date, dateOnly) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const day = String(date.getDate()).padStart(2, '0');
    const hours = dateOnly ? '00' : String(date.getHours()).padStart(2, '0');
    const minutes = dateOnly ? '00' : String(date.getMinutes()).padStart(2, '0');
    const seconds = dateOnly ? '00' : String(date.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
}

const MyInsights = (props) => {
    const [loading, setLoading] = useState(props.loading);
    useEffect(() => {
        setLoading(props.loading);
    }, [props.loading])
   
    const usersData = props.insightData?.userData;
    const managerData = props.isDemo ? manager : props.insightData?.managerData;
    return (
        <WindowContext.Consumer>{
            (context) => (
                <div className='overflow-hidden border-top' style={{ height: "calc(100% - 36px)" }}>
                    <div className="team-insights-container h-100">
                        <div className="row m-0 h-100">
                            {!context.mediaCategory.sm && <div className="col-auto p-0 h-100">
                                <div className="manager-side-pan">
                                    {props.isTeamView
                                        ?
                                        <Card
                                            sidePan
                                            teamView={props.isTeamView}
                                            {...managerData}
                                            fnUpdateUserToView={props.fnUpdateUserToView}
                                            wellBeingScoreDescriptionList={props.wellBeingScoreDescriptionList}
                                            isDemo={props.isDemo }
                                        />
                                        :
                                        <Card
                                            sidePan
                                            teamView={props.isTeamView}
                                            {...usersData}
                                            fnUpdateUserToView={props.fnUpdateUserToView}
                                            wellBeingScoreDescriptionList={props.wellBeingScoreDescriptionList}
                                            isDemo={props.isDemo}
                                        />
                                    }

                                </div>
                            </div>}
                            <div className="col p-0 h-100">
                                <div className="hero-container">

                                    {props.isTeamView ? <></> : 
                                        <> {context.mediaCategory.sm &&
                                                <div className="hero-main mb-4">
                                                    <Card
                                                        userView
                                                        teamView={props.isTeamView}
                                                        {...usersData}
                                                        fnUpdateUserToView={props.fnUpdateUserToView}
                                                        wellBeingScoreDescriptionList={props.wellBeingScoreDescriptionList}
                                                        isDemo={props.isDemo}
                                                    />
                                                </div>
                                            }
                                            <UserView userData={usersData} filterData={props.filterData} isDemo={props.isDemo} />
                                        </>
                                    }

                                    {props.isTeamView && <MyTeamCard
                                        {...props} />}

                                    {props.isTeamView && <div className="hero-main">
                                        {
                                            context.mediaCategory.sm && (usersData != null && usersData.filter((d) => d.userGUID === managerData.userGUID)
                                                .map((d, index) =>
                                                    <Card
                                                        key={index}
                                                        teamView={true}
                                                        {...d}
                                                        fnUpdateUserToView={props.fnUpdateUserToView}
                                                        wellBeingScoreDescriptionList={props.wellBeingScoreDescriptionList}
                                                        isDemo={props.isDemo}
                                                    />
                                                ))
                                        }

                                        {
                                            usersData != null ? usersData.filter((d) => d.userGUID !== managerData.userGUID)
                                                .map((d, index) =>
                                                    <Card
                                                        key={index}
                                                        teamView={true}
                                                        {...d}
                                                        fnUpdateUserToView={props.fnUpdateUserToView}
                                                        wellBeingScoreDescriptionList={props.wellBeingScoreDescriptionList}
                                                        isDemo={props.isDemo}
                                                    />
                                                )
                                                : <></>
                                        }
                                    </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            )
        }
        </WindowContext.Consumer>
    )
}
export default MyInsights