import { ExclamationTriangleIcon, OpenOutsideIcon } from "@fluentui/react-icons-northstar"
import { Checkbox } from "@fluentui/react-northstar"
const DemoBanner = (props) => {
    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
      }
      const handleDemoClick = () => {
        props.setIsDemo(false);
        localStorage.removeItem("contentUrl_dashboards");
        localStorage.removeItem("contentUrl_configuration");
        localStorage.removeItem("demoGlobal");
        localStorage.removeItem("demo");
        localStorage.removeItem("impersonationMode");
        window.location.reload(false);
      };
    return (
        <div className="row rounded-lg cst-shadow signup-banner-config align-items-center alert-primary mx-auto"
                style={{ overflow: "hidden", lineHeight: "1.25", minWidth: props.tenantStatus === 100 ? "320px" : "292px" }}>
                <div className="col">
                    <div className="row">
                        {props.tenantStatus === 100 && <div className="col-auto pl-2" style={{ lineHeight: "1.25" }}>
                            <ExclamationTriangleIcon />
                        </div>}
                        <div className="col pl-0">
                            {props.tenantStatus === 100 ? <div className="row">
                                <div className="col pl-2 pr-0 text-nowrap">
                                    {/* <p className="m-0"
                                    style={{ fontWeight: "300", color: "darkslategray" }}>
                                    Analytics</p>
                                    <p className="m-0"
                                    style={{ fontWeight: "500" }}>365,&nbsp;
                                    </p> */}
                                    Exploring app with sample data,
                                </div>
                                <div className="col-auto pl-2 ">
                                    Sign up <b>NOW</b> !
                                </div>
                            </div> : <div className="col-auto pr-0"><b>Exploring app</b> with sample data.</div>}
                        </div>
                    </div>
                </div>
                {props.tenantStatus === 100 ? <div className="col-auto h-100 p-1">
                    < button type="button" style={{ lineHeight: "1" }}
                        onClick={() => openInNewTab(global.localStorage.getItem("signUpUrl"))}
                        className="btn h-100 btn-primary"><OpenOutsideIcon className='fUi-1_25' /> Sign up
                    </button>
                </div> : <div className="col-auto h-100 p-1">
                    <Checkbox className="demo-checkbox"
                        toggle
                        checked
                        onClick={() => {
                            handleDemoClick();
                        }}
                    />
                </div>}

            </div>
    )
}

export default DemoBanner;